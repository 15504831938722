import tracker from "../tracker";

export default class marketingOptOut {

    constructor(email) {
        this.email = email;
    }

    dispatch() {
        tracker.publish(this)
    }
}