import tracker from "../tracker";

export default class clickToEmail {

  constructor(email) {
    this.email = email
  }

  dispatch() {
    tracker.publish(this)
  }
}