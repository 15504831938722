<template>
  <section class="widget-prefab">
    <form method="POST" action="https://mstore.activehosted.com/proc.php" ref="newsletterFooterForm" novalidate>
      <input type="hidden" name="u" value="14" />
      <input type="hidden" name="f" value="14" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />
      <input type="hidden" name="or" value="1b6f569da968d140cac475356fa1a877" />
      <div class="row">
        <div class="col-12 mb-3">
          <input id="email" name="email" v-model="form.email" placeholder="Email" type="text" class="form-control" required="required" :class="{ 'has-errors': errors.email }" />
          <div v-for="(error, k) in errors.email" :key="k" class="error">{{error}}</div>
        </div>
        <div class="col-12 mb-3">
          <input id="fullname" name="fullname" v-model="form.name" placeholder="Full Name" type="text" class="form-control" :class="{ 'has-errors': errors.name }" />
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto mt-2">
        <component class="" :is="'button-' + config.buttonType" @click.prevent="submitForm()">{{ config.cta }}</component>
      </div>
      </div>
      <div class="notes pt-4" v-if="config.showTerms == 'true'">
        Mstore Ltd would like to keep you updated about our latest news and
        offers. If you are happy for us to do this, please enter your email
        above. See our Privacy Notice for more information on how we handle and
        use personal data including how to withdraw consent at any time.
        <br />View our
        <router-link :to="{ name: 'terms-and-conditions' }">Terms & Conditions</router-link>&nbsp;and
        <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
      </div>
    </form>
  </section>
</template>

<script>
import buttonPrimary from "./ui/buttons/buttonPrimary.vue";
import buttonSecondary from "./ui/buttons/buttonSecondary.vue";
import buttonTertiary from "./ui/buttons/buttonTertiary.vue";
import validator from "./checkout/validator";

export default {
  props: ["config"],
  components: { buttonPrimary, buttonSecondary, buttonTertiary },
  data() {
    return {
      errors: {},
      form: {
        name: "",
        email: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.errors = {};
      validator.is.email(this.form.email).then(
        () => {
          let first_name = "";
          let last_name = "";

          // Check if the name contains a space
          if (this.form.name.indexOf(" ") >= 0) {
            // Split the name into first and last name
            let name = this.form.name.split(" ");
            // set the first name as the first element in the array
            first_name = name[0];
            // set the last name as the rest of the array
            last_name = name.slice(1).join(" ");
          } else {
            first_name = this.form.name;
          }

          this.$store
            .dispatch("auth/optUserNewsletter", {email: this.form.email, first_name: first_name, last_name: last_name, source: "prefab"})
            .finally(() => {
              this.email = null; 
              // redirect to thank you page
              this.$router.push({
                name: "thank-you",
                params: {
                  message: {
                    header: "You have subscribed to our newsletter!",
                    body: "",
                  },
                  ctas: [
                    {
                      message: "CONTINUE SHOPPING",
                      link: "products",
                      type: "primary",
                    },
                  ],
                },
              });
              // Avoid form submission, we will create the contact on AC or Mailchimp from the backend
              // this.$refs.newsletterFooterForm.submit();
            });
        },
        () => {
          this.errors = {
            email: ["Email Address is not valid"],
          };
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  .notes {
    font-size: 0.8em;
    color: gray;
    a {
      color: gray;
    }
  }
  input[type="text"] {
    border: none;
    background: transparent;
    border-bottom: 1px solid black;
  }
}
.error {
  color: red;
}
.has-errors {
  border: 1px solid red;
}
</style>