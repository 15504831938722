import Vue from 'vue'

import is from "./validator.is"
export default new Vue({
    data() {
        return {
            is
        }
    },
    methods: {
        filled(data) {
            return new Promise((resolve, reject) => {
                if (data == null || data == undefined) {
                    reject();
                }
                if (data.length <= 0) {
                    reject();
                }
                resolve();
            })
        }
    }
})