import { filter } from 'lodash'

export default {
  filter(filters, products,sizeMap) {
    if (filters.sizes == 0) {
      return products
    }


    return filter(products, (product) => {
      var found = false;
      product.sizes.forEach(size => {
        var mappedSize = this.mapSize(size.size,sizeMap)
        const i = filters.sizes.indexOf(mappedSize)
        if(i > -1){
          found = true
        }
      });
      return found
    })
  },

  mapSize(size,map) {
    return map[size]
  }
}
