<template>
  <section class="filter-drill-ui" :class="{ showing: showing }" @click="toggle" ref="me">
    <div class="row align-items-center toggle">
      <div class="col">
        <h1 :class="{active:active}">{{ label }}</h1>
      </div>
      <div class="col-auto">
        <icon type="chevronDown" height="24px" width="24px" />
      </div>
    </div>
    <div class="content" @click.stop ref="content">
      <slot ref="filter"></slot>
    </div>
  </section>
</template>

<script>
import drillBus from "./drillBus";

export default {
  props: ["label", "active"],
  data() {
    return {
      showing: false,
      justShown: false,
    };
  },
  mounted() {
    drillBus.$on("hide", () => {
      if (!this.justShown) {
        this.showing = false;
      }
    });
  },
  methods: {
    watchScrollOn() {
      document.addEventListener("scroll", this.onScroll);
    },
    watchScrollOff() {
      document.removeEventListener("scroll", this.onScroll);
    },
    onScroll() {
      const el = this.$refs.content;
      const clientRect = el.getBoundingClientRect();
      const vh = window.innerHeight;
      const newHeight = vh - clientRect.y - 20;

      el.style.maxHeight = newHeight + "px";
    },
    toggle() {
      this.showing = !this.showing;
      if (this.showing) {
        this.justShown = true;
        drillBus.$emit("hide");
        this.$nextTick(() => {
          this.justShown = false;
        });
      }
    },
    clickOutside(event) {
      {
        if (!this.$refs.me) {
          return;
        }
        if (!this.$refs.me.contains(event.target)) {
          this.showing = false;
        }
      }
    },
  },
  watch: {
    showing(showing) {
      if (showing) {
        document.addEventListener("click", this.clickOutside);
        this.watchScrollOn();
        this.onScroll();
      } else {
        document.removeEventListener("click", this.clickOutside);
        this.watchScrollOff();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-drill-ui {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  

  h1 {
    margin: 0px;
    padding: 0px;
    font-size: 1.2em;
    font-weight: normal;

    &.active {
      font-weight: bold;
    }
  }

  .caret {
    font-size: 1.8em;
    padding-top: 5px;
    transition: all 100ms ease-in-out;
  }

  .content {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: white;
    left: 0px;
    width: calc(100%);
    top: 100%;
    z-index: 1;
    background-color: rgb(240, 240, 240);
    padding: 10px;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.3));
    transition: all 250ms ease-in-out;
    max-height: 0px;
    overflow: auto;
    color:black;
  }

  &.showing {
    background-color: #feb103;
    border: none;
    border-radius: 10px 10px 0 0;
    transition: all 250ms ease-in-out;


    .caret {
      transform: rotate(180deg) translateY(-5px);
    }
    .content {
      opacity: 1;
      pointer-events: unset;
      max-height: 300px;

      position: absolute;
    }
  }
}
</style>