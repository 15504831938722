<template>
  <nav>
    <component
      v-if="type"
      :is="type"
      :width="width"
      :height="height"
      fill="currentColor"
      style="display:block"
    ></component>
  </nav>
</template>

<script>
export default {
  components: {
    search: () => import("./icons/search.svg"),
    searchSolid: () => import("./icons/search-solid.svg"),
    user: () => import("./icons/user.svg"),
    userSolid: () => import("./icons/user-solid.svg"),
    cart: () => import("./icons/basket-shopping.svg"),
    tag: () => import("./icons/tag.svg"),
    trash: () => import("./icons/trash-can.svg"),
    plus: () => import("./icons/plus.svg"),
    plusSquare: () => import("./icons/square-plus.svg"),
    share: () => import("./icons/share.svg"),
    minus: () => import("./icons/minus.svg"),
    minusSquare: () => import("./icons/square-minus.svg"),
    times: () => import("./icons/xmark.svg"),
    returns: () => import("./icons/box-check-regular.svg"),
    timesSquare: () => import("./icons/square-xmark.svg"),
    bag: () => import("./icons/bag-shopping.svg"),
    location: () => import("./icons/location-dot.svg"),
    creditCard: () => import("./icons/credit-card.svg"),
    logout: () => import("./icons/logout.svg"),
    bars: () => import("./icons/bars.svg"),
    gem: () => import("./icons/gem.svg"),
    christmasTree: () => import("./icons/christmas-tree.svg"),
    gifts: () => import("./icons/gifts.svg"),
    lock: () => import("./icons/lock.svg"),
    truckRampBox: () => import("./icons/truck-ramp-box.svg"),
    truckFast: () => import("./icons/truck-fast.svg"),
    chevronUp: () => import("./icons/chevron-up.svg"),
    chevronDown: () => import("./icons/chevron-down.svg"),
    chevronRight: () => import("./icons/chevron-right.svg"),
    chevronLeft: () => import("./icons/chevron-left.svg"),
    twitter: () => import("./icons/twitter.svg"),
    youtube: () => import("./icons/youtube.svg"),
    facebook: () => import("./icons/facebook.svg"),
    tiktok: () => import("./icons/tiktok.svg"),
    instagram: () => import("./icons/instagram.svg"),
    happy: () => import("./icons/face-happy.svg"),
    sad: () => import("./icons/face-sad.svg"),
    circleInfo: () => import("./icons/circle-info.svg"),
    circlePlus: () => import("./icons/circle-plus.svg"),
    circleMinus: () => import("./icons/circle-minus.svg"),
    circleExclamation: () => import("./icons/circle-exclamation.svg"),
    check: () => import("./icons/check.svg"),
    download: () => import("./icons/download.svg"),
    mapLocation: () => import("./icons/map.svg"),
    edit: () => import("./icons/edit.svg"),
    square: () => import("./icons/square.svg"),
    squareCheck: () => import("./icons/square-check.svg"),
    filters: () => import("./icons/filter.svg"),
    lightbulb: () => import("./icons/lightbulb.svg"),
  },
  props: {
    type: { type: String },
    height: { type: String, default: "20px" },
    width: { type: String, default: "20px" },
  },
};
</script>

<style lang="scss" scoped>
</style>
