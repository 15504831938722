import Vue from 'vue'
import Vuex from 'vuex'
import auth from './store/auth'
import cart from './store/cart'
import app from './store/app'
import checkout from './store/checkout'
import localforage from 'localforage'


import VuexPersistence from 'vuex-persist'
import cartSync from "./plugins/store/cartSync"

const vuexLocal = new VuexPersistence({
    storage: localforage,
    asyncStorage: true,
})

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        cart,
        app,
        checkout
    },
    plugins: [
        vuexLocal.plugin,
        cartSync,
    ]
})

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
    window.__store__ = store
}

export default store