var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"catlist"},[_vm._m(0),_c('div',{staticClass:"container mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category hand"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("HAND PROTECTION")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Hand Protection',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Hand Protection")])],1)],1)])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category respiratory"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("Respiratory PROTECTION")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Respiratory Protection',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Respiratory Protection")])],1)],1)])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category eye"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("EYE PROTECTION")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Eye Protection',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Eye Protection")])],1)],1)])])]),_c('div',{staticClass:"row mt-4 mb-4"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category hearing"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("HEARING PROTECTION")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Hearing Protection',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Hearing Protection")])],1)],1)])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category clothing"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("PROTECTIVE CLOTHING")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Coveralls',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Protective Clothing")])],1)],1)])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-4 mb-md-4 mb-lg-0"},[_c('div',{staticClass:"category head"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("HEAD PROTECTION")]),_c('router-link',{attrs:{"to":{
                name: 'category',
                params: {
                  category: 'Head Protection',
                  react: true,
                },
              }}},[_c('button-primary',[_vm._v("Shop Head Protection")])],1)],1)])])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h1',[_vm._v("Personal Protective Equipment")]),_c('p',[_vm._v("Welcome to Mstore, your go-to online shop for all your personal protective equipment (PPE) needs. With a commitment to safety and quality, we offer an extensive range of CE-certified PPE products designed to meet and exceed medical standards, ensuring the ultimate protection for trade professionals and weekend DIY enthusiasts alike.")]),_c('h2',[_vm._v("Why Choose Mstore for Your PPE Needs?")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Wide Selection")]),_vm._v(": From PPE gloves that offer dexterity and protection to durable safety glasses that shield your eyes, we have everything you need under one roof.")]),_c('li',[_c('strong',[_vm._v("Quality Assurance")]),_vm._v(": Our products not only meet European Conforming (CE) standards but are also designed with your safety and comfort in mind.")]),_c('li',[_c('strong',[_vm._v("Innovative Solutions")]),_vm._v(": We continuously expand our range with innovative PPE solutions, ensuring you have access to the latest and most effective protection available.")])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("Trusted PPE for Every Task")]),_c('p',[_vm._v(" At Mstore, we understand the importance of reliable PPE in ensuring safety and productivity, whether you're tackling a professional project or a home improvement task. That's why we're dedicated to providing you with trusted PPE solutions that meet your specific needs. ")])])])])}]

export { render, staticRenderFns }