<template>
  <div
    class="mm-item"
    :class="{ showChildren: showChildren }"
    @mouseenter="showMe"
    @mouseleave="hideMe"
    @mousemove="move"
  >
    <router-link :to="to">
      <slot></slot>
    </router-link>
    <div class="mm-content" v-if="hasDropdown">
      <div class="inner">
        <div class="container-fluid">
          <div class="row">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../bus";
export default {
  props: ["to"],
  methods: {
    move() {
      clearTimeout(this.showTimeout);
      this.showMe();
    },
    hideMe() {
      clearTimeout(this.showTimeout);
      this.hideTimeout = setTimeout(() => {
        this.showChildren = false;
      }, 300);
    },
    showMe() {
      if (!this.canShow) {
        return;
      }
      clearTimeout(this.hideTimeout);
      this.showTimeout = setTimeout(() => {
        this.showChildren = true;
        this.justOpened = true;
        bus.$emit("mmopen");
      }, 50);
    },
  },
  watch: {
    $route() {
      this.showChildren = false;
      this.canShow = false;
      setTimeout(() => {
        this.canShow = true;
      }, 500);
    },
    showChildren() {
      if (this.showChildren && this.hasDropdown) {
        this.$emit("active", this);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.canShow = true;
    }, 500);
    bus.$on("mmopen", () => {
      if (!this.justOpened) {
        this.showChildren = false;
      } else {
        this.justOpened = false;
      }
    });
  },
  computed: {
    hasDropdown() {
      return this.$slots.content;
    },
  },
  data() {
    return {
      showChildren: false,
      hideTimeout: null,
      showTimeout: null,
      justOpened: false,
      canShow: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.mm-item {
  background-color: #3b3c3d;
  border-left: 1px solid rgba(black, 0.3);
  display: flex;
  align-items: center;

  &:first-child {
    border-left: none;
  }


  a {
    color: white;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    display: block;
    text-decoration: none;
  }

  &.special {
    background-color: #f4443f;
    a {
      padding-left: 40px;
      padding-right: 40px;
      font-weight: bold;
    }
  }

  &.green {
    background-color: #029F4B;
  }

  &.blue {
    background-color: #279CD9;
  }

  .mm-content {
    padding: 20px;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: white;
    width: 100%;
    min-height: 300px;
    border-radius: 0 0 4px 4px;
    opacity: 0;
    pointer-events: none;
    transform-origin: top center;
    filter: drop-shadow(0px 10px 2px rgba(black, 0.3));
    z-index: 2;

    .inner {
      opacity: 0;
    }
    h5 {
      border-bottom: 2px solid black;
      margin-bottom: 20px;
    }
    a {
      color: black;
      padding: 0px;
      margin-bottom: 5px;
      &:hover {
        color: #feb103;
      }
    }
  }

  &.showChildren {
    background-color: lighten($color: #323232, $amount: 1%);
    transition: all 250ms ease-in-out;
    &.special {
      background-color: darken($color: #f4443f, $amount: 5%);
      a {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .mm-content {
      pointer-events: unset;
      opacity: 1;
      .inner {
        opacity: 1;
        transition: all 500ms ease-in-out;
      }
    }
  }
}
</style>