<template>
  <button class="vave-button vave-button-primary" @click="click($event)" @keydown.tab="handleTab" :disabled="loading || disabled">
    <slot></slot>
    <div class="spinner-border spinner-border-sm ms-2" role="status" v-if="loading">
      <span class="visually-hidden">Loading...</span>
    </div>
  </button>
</template>

<script>

import baseButton from "./baseButton";
export default {
  mixins: [baseButton],
};
</script>

<style lang="scss" scoped>
@import "./buttons.scss";
.vave-button-primary {
  @include vave-button-type(#029F4B);
  color:white
}
</style>