// import EventBus from "../bus"
// import $axios from '../http'

export default {
    namespaced: true,
    state: () => ({
        userIsRegistered: false,
        useShippingAddressForBilling: true,
        currentStep: 1,
        email: null,
        phone: null,
        blankDetails: {
            first_name: null,
            last_name: null,
            company: null,
            address: {
                address_1: null,
                address_2: null,
                postcode: null,
                city: null,
                country: null,
                uprn: null,
                uuid: null,
            },
        },
        billing: {
            first_name: null,
            last_name: null,
            company: null,
            address: {
                address_1: null,
                address_2: null,
                postcode: null,
                city: null,
                country: null,
                uprn: null,
                uuid: null,
            },
        },
        shipping: {
            first_name: null,
            last_name: null,
            company: null,
            address: {
                address_1: null,
                address_2: null,
                postcode: null,
                city: null,
                country: null,
                uprn: null,
                uuid: null,
            },
        },
        errors: {
            steps: {
                one: [],
                two: [],
                three: [],
            },
        },
    }),
    getters: {
        errors(state) {
            return state.errors
        },
        firstStepWithErrors(state) {
            let firstStep = 1
            if (state.errors.steps.two.length > 0) {
                firstStep = 2
            }
            if (state.errors.steps.three.length > 0) {
                firstStep = 3
            }
            return firstStep
        }
    },
    mutations: {
        reset(state) {
            state.currentStep = 1
            state.email = null
            state.phone = null
            state.billing = { ...state.blankDetails }
            state.shipping = { ...state.blankDetails }
            state.useShippingAddressForBilling = true
        },
        addError(state, payload) {
            state.errors.steps[payload.step].push(payload.error)
        },
        clearErrors(state, step) {
            state.errors.steps[step] = []
        },
        clearAllErrors(state) {
            state.errors.steps = {
                one: [],
                two: [],
                three: [],
            }
        },
        setBillingAddress(state, address) {
            state.billing = address
        },
        setEmail(state, email) {
            state.email = email
        },
        setPhone(state, phone) {
            state.phone = phone
        },
        setShippingAddress(state, address) {
            state.shipping = address
        },
        next(state) {
            state.currentStep++
        },
        prev(state) {
            state.currentStep--
        },
        useShippingAddressForBilling(state, value) {
            state.useShippingAddressForBilling = value
        },
        currentStep(state, value) {
            state.currentStep = value
        },
        forceCurrentStep(state, value) {
            state.currentStep = value
        },
        userIsRegistered(state) {
            state.userIsRegistered = true;
        },
        userIsNotRegistered(state) {
            state.userIsRegistered = false;
        },

    },
    actions: {
    }
}