import tracker from "../tracker";

export default class hit {

  constructor(page) {
    this.page = page;
  }

  dispatch() {
    tracker.publish(this)
    window.vgo('update')
  }
}