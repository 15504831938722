<template>
  <div>
    <ui :label="'Applications ' + (checked.length? '(' + checked.length + ')':'')" :active="checked.length">
      <ui-options :options="industries" v-model="checked"></ui-options>
    </ui>
  </div>
</template>

<script>
import ui from "../drillUI";
import uiOptions from "../uiOptions";

export default {
  props: ["value"],
  name: "applications",
  data() {
    return {
      industries: this.map(),
      checked: this.value,
    };
  },

  methods: {
    map() {
      return [...this.$store.state.app.filters.applications].map((b) => {
        return {
          label: b,
          value: b,
          id:b,
          key: b
        }
      })
    },
    hide() {
      console.log("hide");
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.checked = this.value;
      },
    },
    checked() {
      this.$emit("input", this.checked);
    },
  },

  components: {
    ui,
    uiOptions,
  },
};
</script>