<template>
  <div>
    <div v-if="$route.name == 'categories'">
      <category-list />
    </div>

    <div v-else>
      <categoryHeader :category="category" v-if="filters.categories.length == 1 && category.description" />
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="row mb-4">
                <cascade :products="this.$store.state.app.products" v-model="filters" @clear="clearFilters()" />
              </div>
              <div class="row">
                <div class="col">
                  <span class="product-count">Showing {{ products.length }} Products</span>
                </div>
                <div class="col-auto">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <label for="order" style="font-weight: bold">Order</label>
                    </div>
                    <div class="col-auto">
                      <select name id="order" class="form-control" v-model="ordering" @change="fireOrderChangedEvent()">
                        <option value="recommended">Recommended</option>
                        <option value="popular">Popularity</option>
                        <option value="pricel2h">Price: Low to High</option>
                        <option value="priceh2l">Price: High to Low</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-list">
                <div ref="productsContainer" class="row align-items-top" v-if="$store.state.app.productsLoaded">
                  <div class="col-12 col-md-3 product-col" v-for="(product, index) in limitedProducts" :key="product.id">
                    <productListItem :showPrice="true" :product="product" :index="index" :enableHover="true" />
                  </div>
                </div>
                <div v-else>Loading products...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8 pt-3 pb-3">
        <portal-target name="after-products"></portal-target>
      </div>
    </div>

    <!-- FAQs -->
    <div class="row" v-if="faqs.length > 0">
      <div class="col">
        <div class="mt-1" v-for="faq, k in faqs" :key="k">
          <h6>{{faq.question}}</h6>
          <div class="mb-3" v-html="faq.answer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import productListItem from "./productListItem";
import cascade from "../../facetCascade/cascade";
import axios from "./../../http";
import productFilter from "../../productFilter";

import { forEach } from "lodash";
import CategoryList from "./categoryList.vue";
import orderProducts from "./../../orderProducts";
import categoryHeader from "./categoryHeader.vue";

import orderProductsEvent from "../../events/eventTypes/orderProducts";

export default {
  data() {
    return {
      itemLimit: 12,
      ordering: "recommended",
      defaultTitle: "Products",
      faqs: [],
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.setTitle(this.defaultTitle);
    productFilter.clear();
    this.parseRouteParams();
  },

  computed: {
    category() {
      var cat = this.$store.getters["app/getCategoryById"](
        this.filters.categories[0]
      );
      return cat;
    },
    filters: {
      get() {
        return productFilter.filters;
      },
      set(value) {
        productFilter.filters = value;
      },
    },
    products() {
      return this.orderProducts(productFilter.results);
    },

    limitedProducts() {
      return this.products.slice(0, this.itemLimit);
    },
    cat() {
      var category;
      forEach(this.$store.state.app.filters.categories, (cat) => {
        if (cat.id == this.filters.categories[0]) {
          category = cat;
        }
      });
      return category;
    },
    categoryTitle() {
      if (this.cat) {
        return this.cat.name;
      }
      return "PPE Products";
    },
    categoryColour() {
      return this.cat.banner_color || "#323232";
    },
    productCount() {
      return this.products.length;
    },
  },

  watch: {
    categoryTitle() {
      window.setTitle(this.categoryTitle);
      this.getFaqs();
    },
    // products: {
    //   deep: true,
    //   handler() {
    //     // const evt = new viewProductList(this.products);
    //     // evt.dispatch();
    //   }
    // },
    $route() {
      if (
        this.$route.params.react !== false &&
        this.$route.params.react !== null &&
        this.$route.name !== "categories"
      ) {
        productFilter.clear();
        this.parseRouteParams();
      }
      if (this.$route.params.resetFilters == true) {
        productFilter.clear();
      }
    },

    filters: {
      deep: true,
      handler() {
        const urlable = ["brands", "categories", "industries", "applications"];

        const routeMap = {
          brands: {
            name: "brand",
            paramKey: "brand",
          },
          categories: {
            name: "category",
            paramKey: "category",
          },
          industries: {
            name: "industry",
            paramKey: "category",
          },
          applications: {
            name: "application",
            paramKey: "application",
          },
        };

        let filled = [];
        urlable.forEach((aspect) => {
          if (this.filters[aspect].length == 1) {
            filled.push(aspect);
          }
        });

        if (filled.length == 1 && routeMap[filled[0]]) {
          this.$router.replace(this.generateRoute(filled)).catch(() => {});
        }
        if (filled.length !== 1 && this.$route.name !== "categories") {
          this.$router
            .replace({
              name: "products",
              params: {
                react: false,
              },
            })
            .catch(() => {});
        }
      },
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (to.name !== "categories") {
      if (to.params.react == true) {
        this.parseRouteParams(to);
        next();
      }
    }
    next();
  },

  methods: {
    fireOrderChangedEvent() {
      new orderProductsEvent(this.ordering).dispatch();
    },
    getFaqs() {
      if (!this.category || !this.category.id) {
        return;
      }
      axios
        .get(
          process.env.VUE_APP_VAVE_URL +
            "/api/categories/" +
            this.category.id +
            "/faqs"
        )
        .then((r) => {
          if (r.data.data) {
            this.faqs = r.data.data;
          }
        });
    },
    orderProducts(products) {
      return orderProducts(
        products,
        this.ordering,
        this.category ? this.category.ordering_strategy : null
      );
    },

    generateRoute(filled) {
      if (filled[0] == "brands") {
        return {
          name: "brand",
          params: {
            brand: this.filters.brands[0],
            react: false,
          },
        };
      }

      if (filled[0] == "categories") {
        var cat = this.$store.getters["app/getCategoryById"](
          this.filters.categories[0]
        );
        return {
          name: "category",
          params: {
            category: cat.name,
            react: false,
          },
        };
      }

      if (filled[0] == "industries") {
        return {
          name: "industry",
          params: {
            industry: this.filters.industries[0],
            react: false,
          },
        };
      }

      if (filled[0] == "applications") {
        return {
          name: "application",
          params: {
            application: this.filters.applications[0],
            react: false,
          },
        };
      }
    },
    handleScroll() {
      this.itemLimit = 100000;
    },
    clearFilters() {
      productFilter.clear();
    },
    parseRouteParams(to) {
      if (this.$route.name == "categories") {
        return;
      }
      window.scroll(0, 2);
      window.scroll(0, 0);
      let route = this.$route;
      if (to !== undefined) {
        route = to;
      }
      // brands
      if (route.params.brand !== undefined) {
        var brand = this.$store.getters["app/getBrandByName"](
          route.params.brand
        );
        this.filters.brands = [brand];
      }
      //categories
      if (route.params.category !== undefined) {
        var categoryName = this.$store.getters["app/getCategoryByName"](
          route.params.category
        );
        var categorySlug = this.$store.getters["app/getCategoryBySlug"](
          route.params.category
        );
        let cats = [];
        if (categoryName) {
          cats.push(categoryName.id);
        }
        if (categorySlug) {
          cats.push(categorySlug.id);
        }
        // Get the unique values of the array: this is to fix a bug where FFP2/3 were
        // added twice because name and slug were the same.
        this.filters.categories = [...new Set(cats)];
      }
      // industries
      if (route.params.industry !== undefined) {
        this.filters.industries = [route.params.industry];
      }
      // applications
      if (route.params.application !== undefined) {
        this.filters.applications = [route.params.application];
      }
      if (route.params.resetFilters == true) {
        this.clearFilters();
      }
    },
  },

  components: {
    productListItem,
    cascade,
    CategoryList,
    categoryHeader,
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  padding-top: 20px;
}
label {
  transition: all 250ms ease-in-out;
}
.muted {
  opacity: 0.7;
}
.filterlist {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.banner {
  background-color: black;
  margin-bottom: 15px;
  height: 130px;
  border-radius: 5px;
  h1 {
    font-size: 3em;
    text-transform: uppercase;
  }
  .iconHolder {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 28% 100%);
    border-radius: 5px;
    .inner {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 100%;
      transform: translate(20px);
    }
  }
}

.product-count {
  font-size: 1em;
}

#order.form-control {
  -webkit-appearance: menulist;
}
</style>