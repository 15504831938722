import tracker from "../tracker";

export default class inputUpdated {

  constructor(input) {
    this.input = input;
  }

  dispatch() {
    tracker.publish(this)
  }
}