function parseSlug(slug) {
    return slug.replace(/-/g, ' ').replace(/\w\S*/, function (word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
}

const routeProvider = (components) => {
    return [
        {
            path: '/',
            component: components.page,
            name: 'home',
            meta: {
                breadcrumb: 'MStore',
                layout: 'fullWidth',
                serverSlug: 'home',
                page: 1,
                pageTitle: 'UK CE certified PPE & safety wear supplier'
            }
        },
        {
            path: '/coupon/:coupon',
            component: components.page,
            name: 'coupon',
            meta: {
                breadcrumb: 'coupon',
                layout: 'fullWidth',
                serverSlug: 'home',
                page: 1,
                pageTitle: 'UK CE certified PPE & safety wear supplier'
            }
        },
        {
            path: '/about-us',
            component: components.page,
            name: 'about-us',
            meta: {
                pageTitle: 'About Us',
                breadcrumb: 'About Us',
                layout: 'fullWidth',
                serverSlug: 'about-us',
                page: 2,
            }
        },
        {
            path: '/glove-size-guide',
            component: components.page,
            name: 'glove-size-guide',
            meta: {
                pageTitle: 'Glove Size Guide',
                breadcrumb: 'Glove Size Guide',
                layout: 'fullWidth',
                serverSlug: 'glove-size-guide',
                page: 3,
            }
        },
        {
            path: '/glove-cleaning-guide',
            component: components.page,
            name: 'glove-cleaning-guide',
            meta: {
                pageTitle: 'Glove Cleaning Guide',
                breadcrumb: 'Glove Cleaning Guide',
                layout: 'fullWidth',
                serverSlug: 'glove-cleaning-guide',
                page: 4,
            }
        },
        {
            path: '/safe-secure-payments',
            component: components.page,
            name: 'safe-secure-payments',
            meta: {
                pageTitle: 'Safe and Secure Payments',
                breadcrumb: 'Safe and Secure Payments',
                layout: 'fullWidth',
                serverSlug: 'safe-secure-payments',
                page: 5,
            }
        },
        {
            path: '/delivery-and-returns',
            component: components.page,
            name: 'delivery-and-returns',
            meta: {
                pageTitle: 'Delivery and Returns',
                breadcrumb: 'Delivery and Returns',
                layout: 'fullWidth',
                serverSlug: 'delivery-and-returns',
                page: 6,
            }
        },
        {
            path: '/privacy-policy',
            component: components.page,
            name: 'privacy-policy',
            meta: {
                pageTitle: 'Privacy Policy',
                breadcrumb: 'Privacy Policy',
                layout: 'fullWidth',
                serverSlug: 'privacy-policy',
                page: 8,
            }
        },
        {
            path: '/terms-and-conditions',
            component: components.page,
            name: 'terms-and-conditions',
            meta: {
                pageTitle: 'Terms and Conditions',
                breadcrumb: 'Terms and Conditions',
                layout: 'fullWidth',
                serverSlug: 'terms-and-conditions',
                page: 9,
            }
        },
        {
            path: '/contact-us',
            component: components.page,
            name: 'contact-us',
            meta: {
                pageTitle: 'Contact Us',
                breadcrumb: 'Contact Us',
                layout: 'fullWidth',
                serverSlug: 'contact-us',
                page: 11,
            }
        },
        {
            path: '/thanks-for-contacting-us',
            component: components.page,
            name: 'thanks-for-contacting-us',
            meta: {
                pageTitle: 'Thanks for Contacting Us',
                breadcrumb: 'Thanks for Contacting Us',
                layout: 'fullWidth',
                serverSlug: 'thanks-for-contacting-us',
                page: 12,
                sitemap: false,
            }
        },
        {
            path: '/newsletter',
            component: components.page,
            name: 'newsletter',
            meta: {
                pageTitle: 'Newsletter',
                breadcrumb: 'Newsletter',
                layout: 'fullWidth',
                serverSlug: 'newsletter',
                page: 13,
            }
        },
        {
            path: '/subscribed-to-newsletter',
            component: components.page,
            name: 'subscribed-to-newsletter',
            meta: {
                pageTitle: 'Subscribed to newsletter',
                breadcrumb: 'Subscribed to newsletter',
                layout: 'fullWidth',
                serverSlug: 'subscribed-to-newsletter',
                page: 14,
            }
        },
        {
            path: '/preview',
            component: components.realTimePreview,
            name: 'preview',
            meta: {
                breadcrumb: 'Preview',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        {
            path: '/account',
            component: components.account,
            name: 'account',
            meta: {
                breadcrumb: 'Account',
                layout: 'fullWidth',
                sitemap: false,
            },
            children: [
                {
                    path: 'addresses',
                    component: components.accountAddresses,
                    name: 'account-addresses',
                    meta: {
                        breadcrumb: 'Addresses',
                        layout: 'standard',
                        pageTitle: 'Addresses',
                        sitemap: false,
                    }
                },
                {
                    path: 'addresses/create',
                    component: components.accountAddressesAdd,
                    name: 'account-addresses-add',
                    meta: {
                        pageTitle: 'Addresses',
                        breadcrumb: 'Addresses',
                        layout: 'standard',
                        sitemap: false,
                    }
                },
                {
                    path: 'addresses/:id',
                    component: components.accountAddressesEdit,
                    name: 'account-addresses-edit',
                    meta: {
                        pageTitle: 'Addresses',
                        breadcrumb: 'Addresses',
                        layout: 'standard',
                        sitemap: false,
                    }
                },
                {
                    path: 'payments',
                    component: components.accountPayments,
                    name: 'account-payments',
                    meta: {
                        breadcrumb: 'Payments',
                        layout: 'standard',
                        pageTitle: 'Payment methods',
                        sitemap: false,
                    }
                },
                {
                    path: 'details',
                    component: components.accountDetails,
                    name: 'account-details',
                    meta: {
                        breadcrumb: 'Details',
                        layout: 'standard',
                        pageTitle: 'Account details',
                        sitemap: false,
                    }
                },
                {
                    path: 'account/change-password',
                    component: components.accountChangePassword,
                    name: 'account-change-password',
                    meta: {
                        breadcrumb: 'Account details',
                        pageTitle: 'Details',
                        layout: 'standard',
                        sitemap: false,
                    }
                },
                {
                    path: 'orders',
                    component: components.accountOrders,
                    name: 'account-orders',
                    meta: {
                        breadcrumb: 'Orders',
                        layout: 'standard',
                        pageTitle: 'Orders list',
                        sitemap: false,
                    }
                },
                {
                    path: 'orders/:id',
                    component: components.accountOrdersShow,
                    name: 'account-orders-show',
                    meta: {
                        breadcrumb: 'Orders',
                        layout: 'standard',
                        pageTitle: 'Order details',
                        sitemap: false,
                    }
                },
            ]
        },
        {
            path: '/search',
            component: components.searchPage,
            name: 'search',
            meta: {
                pageTitle: "Search",
                breadcrumb: "Search",
                layout: 'standard',
            }
        },
        {
            path: '/checkout',
            component: components.checkout,
            name: 'checkout',
            meta: {
                breadcrumb: 'Checkout',
                layout: 'checkout',
                pageTitle: 'Checkout',
                sitemap: false,
                avoidTrackingEvents: true,
            }
        },
        {
            path: '/reset-checkout',
            component: components.resetCheckout,
            name: 'reset-checkout',
            meta: {
                layout: 'focus',
                pageTitle: 'Reset Checkout',
                sitemap: false,
                breadcrumb: 'Reset Checkout',
            }
        },
        {
            path: '/basket',
            component: components.basket,
            name: 'basket',
            meta: {
                breadcrumb: 'Basket Overview',
                layout: 'standard',
                pageTitle: 'Basket',
                sitemap: false,
                avoidTrackingEvents: true,
            }
        },
        {
            path: '/products',
            component: components.passthrough,
            name: 'products',
            meta: {
                breadcrumb: "Personal Protection Equipment",
                layout: 'standard',
                debug: "Product List Page",
                mainview: components.productList,
                avoidTrackingEvents: true,
            },
            children: [
                {
                    path: 'brands/',
                    component: components.productList,
                    meta: {
                        breadcrumb: 'Brands',
                        layout: 'standard',
                    },
                    children: [
                        {
                            path: ':brand',
                            component: components.productList,
                            name: 'brand',
                            meta: {
                                breadcrumb: routeParams => routeParams.brand,
                                layout: 'standard',
                                debug: "Brand Page",
                                avoidTrackingEvents: true,
                            }
                        }
                    ]
                },
                {
                    path: 'industries/',
                    component: components.productList,
                    meta: {
                        breadcrumb: 'industries',
                        layout: 'standard',
                    },
                    children: [
                        {
                            path: ':industry',
                            component: components.productList,
                            name: 'industry',
                            meta: {
                                breadcrumb: routeParams => routeParams.industry,
                                layout: 'standard',
                                debug: "Brand Page",
                                avoidTrackingEvents: true,
                            }
                        }
                    ]
                },
                {
                    path: 'applications/',
                    component: components.productList,
                    meta: {
                        breadcrumb: 'applications',
                        layout: 'standard',
                    },
                    children: [
                        {
                            path: ':application',
                            component: components.productList,
                            name: 'application',
                            meta: {
                                breadcrumb: routeParams => routeParams.application,
                                layout: 'standard',
                                debug: "Brand Page",
                                avoidTrackingEvents: true,
                            }
                        }
                    ]
                },
                {
                    path: 'categories/',
                    component: components.productList,
                    name: 'categories',
                    meta: {
                        breadcrumb: 'Categories',
                        layout: 'fullWidth',
                        pageTitle: 'Categories',
                    },
                    children: [
                        {
                            path: ':category',
                            name: 'category',
                            component: components.productList,
                            meta: {
                                breadcrumb() {
                                    return {
                                        label: this.$route.params.category,
                                    }
                                },
                                layout: 'standard',
                                debug: "Category Page",
                                avoidTrackingEvents: true,
                            }
                        },
                    ]
                },
                {
                    path: 'view/:product/:option?/:optionValue?',
                    component: components.productInternal,
                    name: 'showProduct',
                    meta: {
                        breadcrumb: routeParams => routeParams.productName,
                        layout: 'standard',
                        debug: "product detail page",
                        sitemap: false,
                        avoidTrackingEvents: true,
                    },
                }
            ]
        },
        {
            path: '/blog',
            component: components.passthrough,
            name: 'blog',
            meta: {
                mainview: components.blog,
                breadcrumb: 'Blog',
                layout: 'standard',
                pageTitle: 'Blog',
            },
            children: [
                {
                    path: 'preview/:previewId',
                    component: components.articleController,
                    name: 'preview-blog-article',
                    meta: {
                        breadcrumb: 'preview',
                        layout: 'standard',
                        mode: 'preview',
                        sitemap: false,
                        avoidTrackingEvents: true,
                    }
                },
                {
                    path: ':slug',
                    component: components.articleController,
                    name: 'show-blog-article',
                    meta: {
                        breadcrumb: routeParams => parseSlug(routeParams.slug),
                        layout: 'standard',
                        debug: "Blog Article",
                        mode: 'view',
                        sitemap: false,
                        avoidTrackingEvents: true,
                    }
                },
            ]
        },
        {
            path: '/register',
            component: components.register,
            name: 'register',
            meta: {
                pageTitle: 'Register',
                breadcrumb: 'Register',
                layout: 'standard',
            }
        },
        {
            path: '/one-click-register',
            component: components.oneClickRegister,
            name: 'one-click-register',
            meta: {
                pageTitle: 'One Click Register',
                breadcrumb: 'One Click Register',
                layout: 'standard',
                sitemap: false,
            }
        },
        {
            path: '/login',
            component: components.login,
            name: 'login',
            meta: {
                pageTitle: 'Login',
                breadcrumb: 'Login',
                layout: 'standard',
            }
        },
        {
            path: '/logout',
            component: components.logout,
            name: 'logout',
            meta: {
                pageTitle: 'Logout',
                breadcrumb: 'Logout',
                layout: 'standard',
                sitemap: false,
                avoidTrackingEvents: true,
            }
        },
        {
            path: '/request-vat-invoice/:orderID',
            component: components.VATInvoiceRequested,
            name: 'request-invoice',
            meta: {
                pageTitle: 'Invoice Requested',
                breadcrumb: 'Invoice requested',
                layout: 'standard',
                sitemap: false,
            }
        },
        {
            path: '/thank-you',
            component: components.thankYou,
            name: 'thank-you',
            meta: {
                pageTitle: 'Thank You',
                breadcrumb: 'Thank You',
                layout: 'standard',
                sitemap: false,
            }
        },
        {
            path: '/forgot-password',
            component: components.forgotPassword,
            name: 'forgot-password',
            meta: {
                pageTitle: 'Forgot Password',
                breadcrumb: 'Forgot Password',
                layout: 'standard',
            }
        },
        {
            path: '/reset-password',
            component: components.resetPassword,
            name: 'reset-password',
            meta: {
                pageTitle: 'Reset Password',
                breadcrumb: 'Reset Password',
                layout: 'standard',
                sitemap: false,
            }
        },
        {
            path: '/riley-sports',
            component: components.special.rileySports,
            name: 'riley-sports',
            meta: {
                pageTitle: 'Riley Sports Eyewear',
                breadcrumb: 'Riley Sports Eyewear',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        {
            path: '/sustainability',
            component: components.special.sustainability,
            name: 'sustainability',
            meta: {
                pageTitle: 'sustainability',
                breadcrumb: 'sustainability',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        {
            path: '/quota-fix',
            component: components.special.quotaFix,
            name: 'quota-fix',
            meta: {
                pageTitle: 'quota-fix',
                breadcrumb: 'quota-fix',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        {
            path: '/:slug/:param1?/:param2?/:param3?/:param4?/:param5?',
            component: components.dynamicPage,
            name: 'dynamic',
            meta: {
                layout: 'fullWidth',
                sitemap: false,
                avoidTrackingEvents: true,
            }
        },

        {
            path: '/product-not-found',
            component: components.productNotFound,
            name: 'productNotFound',
            meta: {
                pageTitle: '404 Product Not found',
                breadcrumb: '404 Product Not found',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        {
            path: '/*',
            component: components.p404,
            name: '404',
            meta: {
                pageTitle: '404 Page Not found',
                breadcrumb: '404 Page Not found',
                layout: 'fullWidth',
                sitemap: false,
            }
        },
        // Note: don't add any route after 404 or they won't be reachable

    ]
}

export default routeProvider
