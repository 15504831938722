<template>
  <div class="container-fluid" v-if="ready">
    <div class="handle d-md-none" @click="showing = !showing">
      Toggle Filters
      <icon type="filters" width="18px" height="18px" />
    </div>

    <div class="cascade d-flex flex-column" :class="{showing:showing}">
      <button-primary class="close d-md-none d-flex" @click="showing = false">
        <icon type="times" width="16px" height="16px" />
      </button-primary>
      <!-- <div class="row">
        <div class="col">
          <pre>{{filters}}</pre>
        </div>
      </div>-->
      <div class="row">
        <div class="col">
          <div class="filters-header">
            <div class="row align-items-center justify-content-between">
              <div class="col">
                <span>Filters</span>
              </div>
              <div class="col-auto">
                <a href @click.prevent="$emit('clear')">Clear Filters</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4" v-for="(drill, index) in activeDrills" :key="index">
          <component :is="drill" @show="drillShown()" v-model="filters[drill.name]" :filters="filters"></component>
        </div>
      </div>
      <div class="row flex-grow-1"></div>
      <div class="row d-md-none">
        <div class="col">
          <button-primary class="block" @click="showing = false">Show Products</button-primary>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  brand,
  coating,
  category,
  liner,
  subcategory,
  price,
  industry,
  suitableapplications,
  lenscoating,
  size,
  attenuation,
  cutlevel,
} from "./drills";

import { forEach } from "lodash";
import filtersChangedEvent from "../events/eventTypes/filtersChanged";
import { buttonPrimary } from "../components/ui/buttons";
import buttonSecondary from "../components/ui/buttons/buttonSecondary";
import { categoryFilter, subcategoryFilter } from "../productFilters";
import debounce from './../debounce'

export default {
  components: {
    buttonPrimary,
    buttonSecondary,
  },

  props: {
    products: {},
    value: {},
  },

  data() {
    return {
      showing: false,
      blockupdates: false,
      filters: { ...this.value },
      Initialfilters: {
        brands: [],
        coatings: [],
        liners: [],
        price: [0, 50],
        categories: [],
        subcategories: [],
        lenscoatings: [],
        attenuationlevels: [],
        cutlevels: [],
      },
      drills: {
        subcategory,
        brand,
        suitableapplications,
        // respiratoryprotectionlevel,
        coating,
        liner,
        category,
        price,
        industry,
        lenscoating,
        size,
        attenuationlevel: attenuation,
        cutlevel,
      },
      alwaysActive: { price: price },
      debounced: debounce((timestamp) => {
        // Fire an event when the filters change, but
        // give it a generous delay to avoid too many events.
        new filtersChangedEvent(this.filters, this.$store.state.cart.cartID, timestamp).dispatch();
      }, 2000),
    };
  },
  mounted() {},
  created() {},
  methods: {
    drillShown() {
      Object.keys(this.drills).forEach((drill) => {
        this.drills[drill].hide();
      });
    },
    prepareFacetNameforDrill(facet) {
      return facet.toLowerCase().replace(/\s+/g, "");
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val, oldVal) {
        if (!this.blockupdates) {
          this.filters = { ...this.value };
        }
        if(val == oldVal){
          return;
        }
        // If at least one filter is set, fire analytics event
        var hasFilters = false;
        forEach(this.value, (filter) => {
          if (filter.length > 0) {
            hasFilters = true;
          }
        });
        if (hasFilters) {
          this.debounced(Date.now());
        }
      },
    },
    filters: {
      deep: true,
      handler() {
        this.blockupdates = true;
        this.$emit("input", this.filters);
        this.$nextTick(() => {
          this.blockupdates = false;
        });
      },
    },
  },
  computed: {
    debug() {
      return process.env.VUE_APP_DEBUG;
    },
    ready() {
      return this.$store.state.app.filtersReady;
    },
    rawProducts() {
      return this.products;
    },
    categoryFilteredProducts() {
      return categoryFilter.filter(
        { categories: this.filters.categories },
        this.rawProducts
      );
    },
    subCategoryFilteredProducts() {
      return subcategoryFilter.filter(
        { subcategories: this.filters.subcategories },
        this.categoryFilteredProducts
      );
    },

    activeDrills() {
      let facets = [];
      this.categoryFilteredProducts.forEach((product) => {
        product.aspects.forEach((aspect) => {
          if (!facets[aspect.name]) {
            let name = this.prepareFacetNameforDrill(aspect.name);
            // "Length" is a reserved word in JS, so we can't use it as a property name
            if (name !== "length") {
              facets[name] = {};
            }
          }
        });
      });

      this.subCategoryFilteredProducts.forEach((product) => {
        if (product.cutLevel) {
          if (!facets["cutlevel"]) {
            facets["cutlevel"] = {};
          }
        }
      });

      let activeDrills = {};
      Object.keys(facets).forEach((facet) => {
        if (this.drills[facet]) {
          activeDrills[facet] = this.drills[facet];
        }
      });

      if (this.filters.categories.length == 1) {
        activeDrills["subcategory"] = this.drills.subcategory;
      }
      var drillOrderMap = [
        "subcategory",
        "price",
        "brand",
        "suitableapplications",
        "industry",
        "attenuationlevel",
        "coating",
        "liner",
        "lenscoating",
        "size",
        "cutlevel",
      ];
      var drills = { ...this.alwaysActive, ...activeDrills };
      var result = [];
      drillOrderMap.forEach((drill) => {
        if (drills[drill]) {
          result.push(drills[drill]);
        }
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";

.filters-header {
  padding: 20px;
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 10px;
  span {
    font-size: 1.3em;
    font-weight: bold;
  }
  a {
    text-decoration: none;
    background-color: rgba(white, 0.9);
    padding: 20px;
    color: black;
    font-weight: bold;
  }
}

.handle {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cascade {
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100vh;
  height: -webkit-fill-available;
  width: calc(100vw - 50px);
  background-color: #8a8a8a;
  padding: 20px;
  transform: translateX(100vw);
  transition: all 250ms ease-in-out;
  // Filters must go over the menu on mobile only
  z-index: 5;
  @include media-breakpoint-up(md) {
    z-index: 1;
  }
  color:white;
  border-radius: 10px;

  .close {
    position: absolute;
    left: -50px;
    top: 0px;
    width: 50px;
    height: 50px;
  }

  &.showing {
    transform: translateX(0vw);
    opacity: 1;
    max-height: 1000px;
    box-shadow: 0 0 100px 100px rgba(black, 0.3);
  }
  @include media-breakpoint-up(md) {
    transform: unset;
    position: relative;
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    box-shadow: none;
  }
}
</style>