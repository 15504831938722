<template>
  <div class="product-list-outer" ref="container" @mouseenter="hoverOn()" @mouseleave="hoverOff()" :class="{lightText,removeBackground,darkBackground,onSaleProduct:product.on_sale}">
    <div class="product-list-item" :class="{ odd: isOdd, noShadows: this.shadows === false, application: over, removeBackground,lightText,darkBackground}" :style="'background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 70%), url(' + actionImage + ');'">
      <div class="row h-100 justify-content-center" :class="{'align-items-center': layout == 'horizontal', 'align-items-between' : layout == 'vertical'}">
        <div class="col-5 text-center" :class="{'col-md-5': layout == 'horizontal', 'col-md-12' : layout == 'vertical', 'order-2': swapped}" v-if="!hideImage">
          <router-link :to="linkObject" draggable="false">
            <img :srcset="srcSet().join(',')" :sizes="sizes().join(',') + ',11px'" width="200" height="200" :alt="product.name + ' ' + product.sub_title" class="img-fluid product-image" draggable="false" />
          </router-link>
        </div>
        <div class="col text-center d-flex flex-column justify-content-between c" :class="{'col-md': layout == 'horizontal', 'col-md-12' : layout == 'vertical'}">
          <router-link :to="{
              name: 'showProduct',
              params: { product: product.slug, productName: product.name },
            }" class="cloaked-link d-flex flex-column h-100 justify-content-between" draggable="false">
            <div v-if="!hideContent">
              <h2 class="mb-1 mt-2" style="font-weight: bold">
                <template v-if="isVariation">{{ product.product.name + ' ' }}</template>
                <template>{{ product.name }}</template>
              </h2>
              <small class="subtitle">{{ product.sub_title | compacted }}</small>
            </div>
            <!-- 
              Problems:
                1 - We don't have the variation prices, so not all the products will display the regular price 
                2 - We say NOW instead of FROM. Is this correct? Needs confirmation from Kieran and Jonjo
            -->
            <h3 class="mb-3 mt-2" style="font-weight: bold" v-if="showPrice">
              <small v-if="!isVariation">
                <span v-if="isOnClearance" style="color:#f4443f;">NOW<br><span style="color:black;">from</span></span>
                <span v-else>from</span>
              </small>
              <span class="price" :class="{'on_clearance': isOnClearance}"> £{{ product.price | asMoney }}</span>
              <br />
              <span class="box-of" v-if="boxOf">(per {{boxOf}})</span>
            </h3>

            <div v-else style="padding-top: 10px"></div>
          </router-link>

          <router-link :to="linkObject" draggable="false">
            <button-red class="block" v-if="isOnClearance">View Product</button-red>
            <button-primary class="block" v-else-if="!altButtons">View Product</button-primary>
            <ButtonRSGrey class="block" v-else-if="altButtons">View Product</ButtonRSGrey>
          </router-link>
          <button-secondary v-if="isVariation && enableQuickBuy && !altButtons" :disabled="product.stock_status == 'outofstock'" class="block mt-2" @click="quickBuy">
            <template v-if="product.stock_status !== 'outofstock'">
              Quick Buy
              <template v-if="!showPrice">
                <small v-if="!isVariation">from</small>
                <span class="price" :class="{'on_clearance': isOnClearance}"> £{{ product.price | asMoney }}</span>
              </template>
            </template>
            <template v-else>Sorry, Out of stock</template>
          </button-secondary>
          <ButtonRSWhite v-if="isVariation && enableQuickBuy && altButtons" :disabled="product.stock_status == 'outofstock'" class="block mt-2" @click="quickBuy">
            <template v-if="product.stock_status !== 'outofstock'">
              Quick Buy
              <template v-if="!showPrice">
                <small v-if="!isVariation">from</small>
                <span class="price"> £{{ product.price | asMoney }}</span>
              </template>
            </template>
            <template v-else>Sorry, Out of stock</template>
          </ButtonRSWhite>
        </div>
      </div>
      <div v-if="isOnClearance">
        <img class="clearance" src="/images/products/clearance.svg" alt="clearance logo">
      </div>
      <div v-else>
        <div class="top10 d-none d-md-flex" v-if="product.rank_sales <= 10">Best Seller</div>
        <div class="top20 d-none d-md-flex" v-if="product.rank_sales <= 20 && product.rank_sales > 10">Popular</div>
        <div class="onSale d-none d-md-flex" v-if="product.on_sale">Limted Time Offer</div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import imageProxy from "../../imageProxy";
import { buttonPrimary } from "../ui/buttons";
import ButtonSecondary from "../ui/buttons/buttonSecondary.vue";
import ButtonRed from "../ui/buttons/buttonRed.vue";
import ButtonRSGrey from "../ui/buttons/buttonRSGrey.vue";
import ButtonRSWhite from "../ui/buttons/buttonRSWhite.vue";

export default {
  components: {
    buttonPrimary,
    ButtonRed,
    ButtonSecondary,
    ButtonRSGrey,
    ButtonRSWhite,
  },
  // props: ["product", "index", "showPrice", "shadows","enableHover","variation","quickbuy"],
  props: {
    product: {
      type: Object,
    },
    index: {
      type: Number,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    shadows: {
      type: Boolean,
      default: true,
    },
    enableHover: {
      type: Boolean,
      default: false,
    },
    isVariation: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: "vertical",
    },
    swapped: {
      type: Boolean,
      default: false,
    },
    enableQuickBuy: {
      type: Boolean,
      default: false,
    },
    removeBackground: {
      type: Boolean,
      default: false,
    },
    darkBackground: {
      type: Boolean,
      default: false,
    },
    lightText: {
      type: Boolean,
      default: false,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    altButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      over: false,
      actionImage: null,
      haveApplication: false,
    };
  },
  computed: {
    isOnSale() {
      return this.product.on_sale;
    },
    isOnClearance() {
      return this.product.theme === 1 ?? false;
    },
    linkObject() {
      if (this.isVariation) {
        return {
          name: "showProduct",
          params: {
            product: this.product.product.slug,
            productName: this.product.product.name + " " + this.product.name,
            option: this.product.aspects[0].name,
            optionValue: this.product.aspects[0].option,
          },
        };
      }
      return {
        name: "showProduct",
        params: { product: this.product.slug, productName: this.product.name },
      };
    },
    skus() {
      var sku = [];
      if (this.product.sku) {
        sku.push(this.product.sku);
      }
      return [...sku, ...this.product.skus];
    },
    isOdd() {
      return this.index % 2;
    },
    boxOf() {
      var aspect = this.getAspectByName("Price Per");
      if (
        aspect !== undefined &&
        aspect.options[0] &&
        aspect.options[0].name &&
        aspect.options[0].name.startsWith("Box of")
      ) {
        return aspect.options[0].name;
      }
      return null;
    },
    reusability() {
      var aspect = this.getAspectByName("Reusability");
      if (aspect !== undefined) {
        return aspect.options[0].name;
      }
      return null;
    },
  },
  mounted() {
    let image = this.product.images.find((image) => {
      return image.type == "application";
    });
    if (image) {
      this.haveApplication = true;
    }
    this.actionImage = image ? imageProxy.image(image.origin_src, 500) : "";
  },
  methods: {
    quickBuy() {
      const promise = this.$store.dispatch("cart/addToCart", {
        sku: this.product.sku,
        debounced: true,
        product: this.product.product,
        variation: this.product,
        quantity: 1,
      });
      promise.then(
        () => {
          //success
        },
        (e) => {
          this.$store.commit("app/addNotification", {
            type: "bad",
            title: "Sorry",
            message: e.message,
          });
        }
      );
    },
    hoverOn() {
      if (this.haveApplication && this.enableHover) {
        this.over = true;
      }
    },
    hoverOff() {
      this.over = false;
    },
    getAspectByName(name) {
      var found;
      this.product.aspects.forEach((aspect) => {
        if (aspect.name == name) {
          found = aspect;
        }
      });
      return found;
    },
    srcSet() {
      var image = this.product.images[0];
      if (image !== undefined) {
        var source = this.product.images[0].origin_src;
        return [
          this.imageLine(source, 500, 500, "500w"),
          this.imageLine(source, 400, 400, "400w"),
          this.imageLine(source, 300, 300, "300w"),
        ];
      }
      return [];
    },
    sizes() {
      const sizes = [1400, 1200, 922, 768, 576];
      const imageSizes = [500, 400, 300, 500, 500];
      return sizes.map((size, index) => {
        return "(min-width: " + size + "px) " + imageSizes[index] + "px";
      });
    },
    imageLine(source, w, h, label) {
      return imageProxy.image(source, w) + " " + label;
    },
    image() {
      var image = this.product.images[0];
      if (image !== undefined) {
        var source = this.product.images[0].origin_src;
        return imageProxy.image(source, 200, 200);
      }

      return "";
    },
  },

  filters: {
    ucfirst(value) {
      return value[0].toUpperCase() + value.toLowerCase().substring(1);
    },
    compacted(value) {
      let maxL = 40;
      if (value.length > maxL) {
        return value
          .substring(0, maxL)
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/\s([^\s]*)$/, "...");
      }
      return value;
    },
    asMoney(value) {
      return (Math.round((value + Number.EPSILON) * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";

h1 {
  font-size: 1.2em;
  text-transform: uppercase;
}

h2 {
  font-size: 1.2em;
  text-transform: capitalize;
  small {
    font-size: 0.5em;
    display: block;
    font-weight: normal;
    opacity: 0.8;
  }
}

.product-list-outer {
  height: 100%;
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  @include media-breakpoint-up(md) {
    border-bottom: none;
    padding-bottom: 25px;
  }
  &.removeBackground, &.darkBackground {
    border-bottom: none;
  }
}

.onSaleProduct {
  .price {
    color:#009f4b;
  }
  .regular-price{
    text-decoration: line-through;
    font-weight: normal;
    font-size: 0.8em;
  }
}

.price.on_clearance {
  color: #f4443f;
}

.product-list-item {
  overflow: hidden;
  background: rgba(white, 1);
  &.removeBackground {
    background: transparent;
    background-image: none !important;
    border: none !important;

    img {
      border-radius: 10px;
    }
  }
  &.darkBackground {
    background: darken(white, 80%);
    background-image: none !important;
    border: none !important;

    img {
      border-radius: 10px;
    }
  }
  color: black;

  position: relative;
  padding: 10px;
  // padding-top: 50px;
  // border: 1px solid rgb(240, 240, 240);
  height: 100%;
  @include media-breakpoint-up(md) {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);

    &.noShadows {
      box-shadow: none;
      border: 1px solid rgb(238, 238, 238);
    }

    background-image: var(--hover-bg-image);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .c {
    position: relative;
    // z-index: 2;
  }
  .product-image {
    box-shadow: 0 0 0 500px rgba(255, 255, 255, 1);
    transition: all 250ms ease-in-out;
    // z-index: 1;
    position: relative;
    background: white;
  }

  &.removeBackground,
  &.darkBackground {
    .product-image {
      box-shadow: none;
    }
  }

  .price-label {
    font-size: 0.8em;
    font-weight: normal;
    color: gray;
    text-transform: none;
  }

  &.application {
    .product-image {
      // box-shadow: 0 0 10px 0px rgba(255, 255, 255, 1);
      // border-radius: 50%;
      opacity: 0;
    }
  }

  .cloaked-link {
    color: rgb(53, 53, 53);
    text-decoration: none;
  }
}

.brand-image {
  width: 80px;
  position: absolute;
  top: 10px;
  right: 0px;
  // background: rgba(white, 0.8);
  border-radius: 10px 0 0 10px;
  padding: 10px;

  box-shadow: -6px 0 7px rgba(0, 0, 0, 0.2);
}

.view-product {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #feb103;
  border: none;
  color: black;
  padding: 10px 20px;
  border-radius: 3px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background: rgb(43, 43, 43);
  }
}

.subtitle {
  margin-top: -2px;
  opacity: 0.8;
  font-size: 0.8em;
}

.lightText {
  h1 {
    color: white;
  }
  h2 {
    color: darken(white, 5%);
  }
  .subtitle {
    color: darken(white, 10%);
  }
}
.top10 {

  position: absolute;
  top:20px;
  left: -100px;
  transform: rotate(-45deg);
  background: #feb103;
  filter: drop-shadow(5px 5px 3px rgba(#777777,0.2));
  width:300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 1;
}

.clearance {
  position: absolute;
  bottom: 8px;
  left: 20px;
  width: 100px;
  height: 100px;
  pointer-events: none;
  opacity: 1;
  @include media-breakpoint-up(md) {
    top: 10px;
    right: 10px;
    left: auto;
    bottom: auto;
    width: 100px;
    height: 100px;
  }
}


.onSale {

  position: absolute;
  top:0px;
  left: 0px;
  // transform: rotate(-45deg);
  background: #feb103;
  // filter: drop-shadow(5px 5px 3px rgba(#777777,0.2));
  width:100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  font-weight: bold;
  display:none;

}

.top20 {

  position: absolute;
  top:20px;
  left: -100px;
  transform: rotate(-45deg);
  background: #009f4b;
  filter: drop-shadow(5px 5px 3px rgba(#777777,0.2));
  width:300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 1;

}
</style>