import $axios from '../../http'
import { forEach } from 'lodash'
// import EventBus from "../../bus";
import shippingMethods from '../../shippingMethods'
import debounce from '../../debounce'

async function sendItemToServer(mutation, store) {
  let cartid = await store.dispatch('cart/getCartId')
  const item = mutation.payload
  const id = item.variation.id ? item.variation.id : item.product.id
  var payload
  if (item.variation.id) {
    payload = {
      line_items: [
        {
          variation_id: item.variation.id,
          quantity: item.quantity,
        },
      ],
    }
  } else {
    payload = {
      line_items: [
        {
          product_id: item.product.id,
          quantity: item.quantity,
        },
      ],
    }
  }

  const response = $axios({
    method: 'post',
    url: process.env.VUE_APP_VAVE_URL + '/api/carts/' + cartid + '/items',
    data: payload,
    withCredentials: true,
  })

  response.then(
    (r) => {
      shippingMethods.setMethods()
      forEach(r.data.data, (e) => {
        const type = item.variation.id
          ? 'App\\Models\\Variation'
          : 'App\\Models\\Product'
        if (e.item_id == id && e.item_type == type) {
          // console.log('adding uuid', {
          //   item: item,
          //   uuid: e.id,
          // })
          store.commit('cart/setItemUUID', {
            item: item,
            uuid: e.id,
          })
        }
      })
    },
    (e) => {
      console.error(e)
    },
  )
}

export default {
  _sendItemToServer(mutation, store) {
    sendItemToServer(mutation, store);
  },
  _addItem: debounce((mutation, store) => {
    sendItemToServer(mutation, store);
  }, 300),

  _deleteItem: debounce((mutation, store) => {
    store.dispatch('cart/getCartId').then((cartid) => {
      const item = mutation.payload

      const response = $axios({
        method: 'delete',
        url:
          process.env.VUE_APP_VAVE_URL +
          '/api/carts/' +
          cartid +
          '/items/' +
          item.uuid,
        withCredentials: true,
      })

      response.then(
        () => {
          shippingMethods.setMethods()
          // console.log(r)
        },
        (e) => {
          console.error(e)
        },
      )
    })
  }, 300),

  _setQty: debounce((mutation, store) => {
    store.dispatch('cart/getCartId').then((cartid) => {
      const item = mutation.payload.item
      const response = $axios({
        method: 'put',
        data: {
          quantity: item.quantity,
        },
        url:
          process.env.VUE_APP_VAVE_URL + '/api/carts/' + cartid + '/items/' + item.uuid,
        withCredentials: true,
      })

      response.then(
        () => {
          shippingMethods.setMethods()
        },
        (e) => {
          console.error(e)
        },
      )
    })
  }),
}
