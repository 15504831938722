import tracker from "../tracker";

export default class visitPage {

  constructor(page, meta) {
    this.page = page;
    this.meta = meta;
  }

  dispatch() {
    tracker.publish(this)
  }
}