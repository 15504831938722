import tracker from "../tracker";

export default class addToCart {

  constructor(cartId, item) {
    this.cartId = cartId;
    this.item = item;
  }

  dispatch() {
    tracker.publish(this)
  }
}