import tracker from "../tracker";

export default class addToCart {

  constructor(cartId, product, variation, quantity, origin = 'normal') {
    this.cartId = cartId;
    this.product = product;
    this.variation = variation;
    this.quantity = quantity;
    this.origin = origin;
  }

  dispatch() {
    tracker.publish(this)
  }
}