// sends tracking events to GA
// SUPPORTED EVENTS
// ------------------
// viewProduct
// selectVariation
// viewProductList
// addToCart
// register
// checkout
// beginCheckout
// stepCheckout

import Vue from 'vue'

import selectVariationEvent from './eventTypes/selectVariation'
import viewProductEvent from './eventTypes/viewProduct'
import viewProductList from './eventTypes/viewProductList'
import addToCart from './eventTypes/addToCart'
import register from './eventTypes/register'
import checkout from './eventTypes/checkout'
import beginCheckout from './eventTypes/beginCheckout'
import stepCheckout from './eventTypes/stepCheckout'
import hit from './eventTypes/hit'
import clickToCallEvent from './eventTypes/clickToCall'
import clickToEmailEvent from './eventTypes/clickToEmail'
import newsletterSubscribeEvent from './eventTypes/newsletterSubscribe'
import formSubmitEvent from './eventTypes/formSubmit'

export default new Vue({
  data() {
    return {
      google: null,
      storedEvents: [],
    }
  },
  created() {
    const gaInterval = window.setInterval(() => {
      if (window.ga !== undefined && window.ga.getAll) {
        const trackers = window.ga.getAll()
        if (trackers) {
          this.google = trackers[0]
          this.handleStoredEvents()
        }
        clearInterval(gaInterval)
      }
    }, 10)
  },
  methods: {
    mapProducts(products) {
      return products.map((product) => {
        return {
          item_id: product.id,
          item_name: product.name,
          item_brand: product.brand,
          price: product.price,
          quantity: product.quantity,
        }
      })
    },
    init(tracker) {
      tracker.subscribe(this.event)
    },
    storeEvent(event) {
      this.storedEvents.push(event)
    },
    handleStoredEvents() {
      this.storedEvents.forEach((event) => {
        this.event(event)
      })
    },
    event(event) {
      // eslint-disable-next-line no-constant-condition
      if (true == false) {
        this.storeEvent(event)
      } else {
        if (event instanceof viewProductEvent) {
          this.viewProduct(event.product)
        }
        if (event instanceof selectVariationEvent) {
          this.selectVariation(event.variation)
        }
        if (event instanceof viewProductList) {
          this.viewItemList(event.products)
        }
        if (event instanceof addToCart) {
          this.addToCart(event)
        }
        if (event instanceof register) {
          this.register(event)
        }
        if (event instanceof beginCheckout) {
          this.checkoutStep(event, true)
        }
        if (event instanceof stepCheckout) {
          this.checkoutStep(event)
        }
        if (event instanceof checkout) {
          this.checkout(event)
        }
        if (event instanceof hit) {
          this.hit(event.page)
        }
        if (event instanceof clickToCallEvent) {
          this.phoneCall(event.phone)
        }
        if (event instanceof clickToEmailEvent) {
          this.email(event.email)
        }
        if (event instanceof newsletterSubscribeEvent) {
          this.newsletter()
        }
        if (event instanceof formSubmitEvent) {
          this.contact()
        }
      }
    },
    phoneCall() {
      window.gtag('event', 'MobileCall', {
        event_category: 'Contact',
        event_action: 'MobileCall',
        event_label: 'wcevent',
      })
    },
    email() {
      window.gtag('event', 'Email', {
        event_category: 'Contact',
        event_action: 'Email',
        event_label: 'wcevent',
      })
    },
    newsletter() {
      window.gtag('event', 'Newsletter signup', {
        event_category: 'Form Submits',
        event_action: 'Newsletter signup',
        event_label: 'wcevent',
      })
    },
    contact() {
      window.gtag('event', 'contact us page form submission', {
        event_category: 'Form Submits',
        event_action: 'Form Submits',
        event_label: 'wcevent',
      })
    },
    createAccount() {
      window.gtag('event', 'Create account form submission', {
        event_category: 'Form Submits',
        event_action: 'Create account',
        event_label: 'wcevent',
      })
    },
    liveChat() {
      window.gtag('event', 'Livechat', {
        event_category: 'LiveChat',
        event_action: 'Submit message',
        event_label: 'wcevent',
      })
    },
    hit(page) {

      window.gtag('event', 'page_view', {
        page_path: page,
      })
      // this.google.set('page', page)
      // this.google.send('pageview')
    },
    checkoutStep(event, first) {
      if (first) {
        window.gtag('event', 'begin_checkout', {
          shipping: event.shipping,
          value: event.total,
          items: this.mapProducts(event.products),
        })
      } else {
        window.gtag('event', 'checkout_progress', {
          shipping: event.shipping,
          value: event.total,
          items: this.mapProducts(event.products),
          checkout_step: event.checkout_step,
        })
      }
    },
    checkout(event) {
      const mappedProducts = this.mapProducts(event.products)
      window.gtag('event', 'purchase', {
        transaction_id: event.orderId,
        shipping: event.shipping,
        value: event.total,
        currency: 'GBP',
        items: mappedProducts,
      })
    },
    register() {
      window.gtag('event', 'sign_up')
      this.createAccount()
    },
    addToCart(event) {
      var item = {
        item_id: event.product.sku,
        item_name: event.product.name,
        item_brand: event.product.brand,
        quantity: event.quantity,
        price: event.product.price,
      }

      if (event.variation.id) {
        item.price = event.variation.price
        item.item_id = event.variation.sku
        item.item_name = item.item_name + ' ' + event.variation.name
      }
      const payload = {
        items: [item],
      }
      window.gtag('event', 'add_to_cart', payload)
    },
    viewItemList(products) {
      const chunks = chunkArray(products, 50)
      chunks.forEach((chunk) => {
        var payload = {
          items: chunk.map((product) => {
            return {
              item_id: product.id,
              item_name: product.name,
              item_brand: product.brand,
            }
          }),
        }
        window.gtag('event', 'view_item_list', payload)
      })
    },
    viewProduct(product) {
      window.gtag('event', 'view_item', {
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: product.brand,
          },
        ],
      })
    },
    selectVariation(product) {
      window.gtag('event', 'view_item', {
        items: [
          {
            item_id: product.sku,
            item_name: product.name,
            item_brand: product.brand,
            price: product.price,
            item_variant: product.name,
          },
        ],
      })
    },
  },
})

function chunkArray(array, size) {
  let result = []
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size)
    result.push(chunk)
  }
  return result
}
