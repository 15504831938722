<template>
  <div>
    <div
      class="trustpilot-widget"
      data-locale="en-GB"
      :data-template-id="template"
      data-businessunit-id="6008460d7a6379000142b593"
      :data-style-height="heightpx"
      data-style-width="100%"
      :data-theme="theme"
      :id="'tp' + id"
    >
      <a
        href="https://uk.trustpilot.com/review/retail.mstore.co.uk"
        target="_blank"
        rel="noopener"
        >Trustpilot</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'dark'
    },
    template: {
      type: String,
    },
    height: {
      type: Number,
      default: 24
    }
  },
  computed: {
    id() {
      return this._uid
    },
    heightpx() {
      return this.height + 'px';
    }
  },
  watch: {
    skus() {
      const tpInterval = window.setInterval(() => {
        if (window.Trustpilot !== undefined) {
          this.initTrustPilot();
          clearInterval(tpInterval);
        }
      }, 10);
    },
  },
  mounted() {
    const tpInterval = window.setInterval(() => {
      if (window.Trustpilot !== undefined) {
        this.initTrustPilot();
        clearInterval(tpInterval);
      }
    }, 10);
  },
  methods: {
    initTrustPilot() {
      window.Trustpilot.loadFromElement(
        document.getElementById('tp' + this.id)
      );
    },
  },
};
</script>