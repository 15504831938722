<template>
  <div class="newsletter-modal" :class="[showNewsletter ? 'visible' : '']">
    <div class="ui popup col col-sm-6 mx-1">
      <div class="dismiss" @click="dismissNewsletterModal">
        <icon type="times" width="25px" height="25px" />
      </div>
      <div class="p-2">
        <newsletter :config="this.config" />
      </div>
    </div>
  </div>
</template>

<script>
import newsletter from "./newsletterForm";

export default {
  components: { newsletter },
  data() {
    return {
      config: {
        buttonType: "primary",
        cta: "Signup for 10% off Your first order",
        showTerms: "true",
      },
    };
  },
  mounted() {
    this.$store.commit("app/showNewsletterPopup", false);
  },
  computed: {
    showNewsletter() {
      return this.$store.state.app.showNewsletterPopup;
    },
  },
  watch: {
    $route() {
      this.$store.commit("app/showNewsletterPopup", false);
    },
    showNewsletter(value) {
      // Block the page scroll when the modal is open
      document.body.style.overflow = value == true ? "hidden" : "auto";
    },
  },
  methods: {
    dismissNewsletterModal() {
      this.$store.commit("app/dismissNewsletterPopup");
    },
  },
};
</script>

<style lang="scss" >
.newsletter-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .popup {
    form {
      border: 0px solid black;
    }
    position: relative;
    .dismiss {
      text-align: right;
      color: gray;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgba(83, 83, 83, 0.9) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background-size: 200% 200%;
  background-position: 50%;
  backdrop-filter: blur(4px) grayscale(100%);
  z-index: 901;
  .ui {
    max-width: 450px;
    background: rgb(245, 245, 245);
    padding: 20px;
    border-radius: 5px;
    .form-control {
      margin-top: 15px;
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: unset;
    background-size: 100% 100%;
  }
}

a {
  color: black;
  &:hover {
    color: #feb103;
  }
}
</style>