import tracker from "../tracker";

export default class couponAdded {

  constructor(cartId, coupon) {
    this.coupon = coupon;
    this.cartId = cartId;
  }

  dispatch() {
    tracker.publish(this)
  }
}