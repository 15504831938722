import Vue from 'vue';
import ga from "./ga";
import fb from "./fb";
import bing from "./bing";
import marketingOptIn from "./marketingOptIn";
import vave from "./vave";

export default new Vue({
  data() {
    return {
      subscriptions: []
    }
  },
  methods: {
    subscribe(callback) {
      this.subscriptions.push(callback);
    },
    publish(event) {
      // console.log('event', event);
      this.subscriptions.forEach(callback => {
        callback(event)
      })
    }
  },
  created() {
    ga.init(this);
    fb.init(this);
    bing.init(this);
    marketingOptIn.init(this);
    vave.init(this);
  }
});