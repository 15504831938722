<template>
  <portal to="before-breadcrumbs">
    <section class="category-header d-md-flex" :style="'background-image: url(' + category.banner_image + ');'">
      <div class="container">
        <div class="row align-items-center justify-content-start">
          <div class="col-12 col-md-6 text-left content-box">
            <h1 class="text-left">{{category.name}}</h1>
            <div v-html="intro"></div>

            <portal to="after-products">
              <div class="d-md-none mb-3" v-html="intro"></div>
              <div v-html="more" v-if="more"></div>
            </portal>
          </div>
        </div>
      </div>
    </section>
  </portal>
</template>

<script>
export default {
  props: ["category"],
  mounted() {
    if (this.category.yoast_head) {
      window.setYoast(this.category.yoast_head);
    }
  },
  computed: {
    description() {
      //take the data sent by the backend and clean it up.
      //split it into an array on each newline and discard empty lines
      //clean each element to remove any html tags before returning
      var paragraphs = this.category.description
        .split(/\r?\n/)
        .filter((element) => element.length);
      var cleanedParagraphs = [];
      paragraphs.forEach((paragraph) => {
        cleanedParagraphs.push(this.stripHtml(paragraph));
      });
      return cleanedParagraphs;
    },
    intro() {
      return this.description[0];
    },
    more() {
      var more = this.description;
      more.shift();
      if (more.length) {
        var output = "";
        more.forEach((paragraph) => {
          output += "<p>" + paragraph + "</p>";
        });
        return output;
      }
      return false;
    },
  },
  methods: {
    stripHtml(dirty) {
      const doc = new DOMParser().parseFromString(dirty, "text/html");
      return doc.body.textContent || "";
    },
  },
};
</script>



<style lang="scss">
@import "src/scss/breakpoints.scss";
.category-header {
  font-size: 0.9em;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  color: darken(white, 10%);
  background: no-repeat right center;
  background-size: cover;
  background-color: gray;
  .content-box {
    color: black;
  }
  h1 {
    font-size: 2.5em;
  }
  @include media-breakpoint-down(md) {
    background: no-repeat left center;
  }
}
</style>
