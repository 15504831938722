import EventBus from '../bus'
import $axios from '../http'
import { forEach } from 'lodash'
import shippingMethods from '../shippingMethods'
import removeFromCartEvent from "../events/eventTypes/removeFromCart";

export default {
  namespaced: true,

  state: () => ({
    cartID: null,
    totalsPending: false,
    items: [],
    coupon: {
      code: null,
      message: null,
      added: false,
      id: null,
    },
    serverTotals: {
      total: {
        cents: 0,
        float: 0.0,
      },
      before_coupons: {
        cents: 0,
        float: 0.0,
      },
      before_discounts: {
        cents: 0,
        float: 0.0,
      },
    },
    shippingMethods: [],
    shippingMethod: {
      id: null,
      title: null,
      price: 0,
      price_in_cents: 0,
    },
    shippingAddress: {
      address_1: '',
      address_2: '',
      postcode: '',
      city: '',
      country: '',
      uprn: '',
      uuid: '',
    },
    billingAddress: {
      address_1: '',
      address_2: '',
      postcode: '',
      city: '',
      country: '',
      uprn: '',
      uuid: '',
    },
    qualifiedForFreeEvo: false,
  }),

  mutations: {
    clear(state) {
      state.totalsPending = false
      state.items = []
      state.cartID = null
      state.coupon = {
        code: null,
        message: null,
        added: false,
        id: null,
      }
      state.serverTotals = {
        total: {
          cents: 0,
          float: 0.0,
        },
        before_coupons: {
          cents: 0,
          float: 0.0,
        },
        before_discounts: {
          cents: 0,
          float: 0.0,
        },
      }
      state.shippingMethods = []
      state.shippingMethod = {
        id: null,
        title: null,
        price: 0,
        price_in_cents: 0,
      }
      state.shippingAddress = {
        address_1: '',
        address_2: '',
        postcode: '',
        city: '',
        country: '',
        uprn: '',
        uuid: '',
      }
      state.billingAddress = {
        address_1: '',
        address_2: '',
        postcode: '',
        city: '',
        country: '',
        uprn: '',
        uuid: '',
      }
    },
    clearAddress(state) {
      state.shippingMethods = []
      state.shippingMethod = {
        id: null,
        title: null,
        price: 0,
        price_in_cents: 0,
      }
      state.shippingAddress = {
        address_1: '',
        address_2: '',
        postcode: '',
        city: '',
        country: '',
        uprn: '',
        uuid: '',
      }
      state.billingAddress = {
        address_1: '',
        address_2: '',
        postcode: '',
        city: '',
        country: '',
        uprn: '',
        uuid: '',
      }
    },
    setCouponMessage(state, message) {
      state.coupon.message = message
    },
    updateProductItem(state, payload) {
      // Update the copy of the product/variation in the Item
      // with the updated product/variation
      var foundIndex = state.items.findIndex((x) => x.uuid == payload.item.uuid)
      var oldPrice = state.items[foundIndex].product.price
      var newPrice = payload.product.price
      state.items[foundIndex].product = payload.product
      if (payload.variation) {
        oldPrice = state.items[foundIndex].variation.price
        newPrice = payload.variation.price
        state.items[foundIndex].variation = payload.variation
      }
      // If there was a price increase, show a notification
      if (newPrice > oldPrice) {
        this.commit('app/addNotification', {
          type: 'warn',
          title: 'Product Price Increased',
          message:
            'The price of ' +
            payload.product.name +
            ' product has increased from £' +
            oldPrice +
            ' to £' +
            newPrice,
          sticky: true,
        })
      }
    },
    addItem(state, item) {
      state.items.push(item)
    },
    addItemUndebounced(state, item) {
      state.items.push(item)
    },
    deleteItem(state, item) {
      const index = state.items.indexOf(item)
      if (index > -1) {
        state.items.splice(index, 1)
        // Fire event
        new removeFromCartEvent(
          state.cartID,
          item
        ).dispatch();
      }
    },
    setQty(state, payload) {
      var qty
      if (payload.qty < 0) {
        qty = 0
      } else {
        qty = payload.qty
      }
      state.items.forEach((item) => {
        if (item.sku == payload.item.sku) {
          item.quantity = qty
        }
      })
    },
    setCoupon(state, coupon) {
      state.coupon.code = coupon
    },
    removeCoupon(state) {
      state.coupon.code = null
    },
    setServerTotals(state, totals) {
      state.serverTotals = totals
    },
    totalsPending(state, value) {
      state.totalsPending = value
    },
    couponApplied(state, value) {
      state.coupon.applied = value
    },
    couponId(state, value) {
      state.coupon.id = value
    },
    setItemUUID(state, payload) {
      state.items.forEach((item) => {
        if (item.sku == payload.item.sku) {
          item.uuid = payload.uuid
        }
      })
    },
    setShippingAddress(state, address) {
      state.shippingAddress = address
    },
    setShippingMethods(state, methods) {
      state.shippingMethods = methods
    },
    setShippingMethod(state, method) {
      state.shippingMethod = method
    },
    setCartID(state, id) {
      state.cartID = id
    },
    setQualifiedForFreeEvo(state, value) {
      state.qualifiedForFreeEvo = value
    },
    deleteCartID(state) {
      state.cartID = null
    },
    replaceCartItems(state, items) {
      state.items = items;
    }
  },

  actions: {
    async getServerCart(store, cartID) {
      if (store.state.items.length == 0) {
        const response = $axios({
          method: 'get',
          url: process.env.VUE_APP_VAVE_URL + '/api/carts/' +
            cartID +
            '/items',
          withCredentials: true,
        })

        response.then((r) => {
          store.commit('setCartID', cartID)
          store.commit('replaceCartItems', r.data.data);
        })
      }
    },
    async getCartId(store) {
      return new Promise((resolve) => {
        if (store.state.cartID !== null) {
          resolve(store.state.cartID)
        } else {
          const response = $axios({
            method: 'post',
            url: process.env.VUE_APP_VAVE_URL + '/api/carts',
            withCredentials: true,
          })
          response.then((r) => {
            this.commit('cart/setCartID', r.data.cart_id)
            resolve(r.data.cart_id)
          })
        }
      })
    },
    async syncCartWithServer(store) {
      store.dispatch('getCartId').then((cartid) => {
        var payload = { line_items: [] }
        forEach(store.state.items, (item) => {
          if (item.variation.id) {
            payload.line_items.push({
              variation_id: item.variation.id,
              quantity: item.quantity,
            })
          } else {
            payload.line_items.push({
              product_id: item.product.id,
              quantity: item.quantity,
            })
          }
        })
        // If the cart is empty, delete the items from the backend too
        if (payload.line_items.length == 0) {
          $axios({
            method: 'delete',
            url:
              process.env.VUE_APP_VAVE_URL +
              '/api/carts/' +
              cartid +
              '/empty',
            withCredentials: true,
          }).then(
            () => {
              EventBus.$emit('cartSyncDone')
            },
            (e) => {
              console.error(e)
            },
          )
          return;
        }

        const response = $axios({
          method: 'post',
          url:
            process.env.VUE_APP_VAVE_URL +
            '/api/carts/' +
            cartid +
            '/items/replace',
          data: payload,
          withCredentials: true,
        })

        response.then(
          (r) => {
            shippingMethods.setMethods()
            forEach(r.data.data, (e) => {
              forEach(store.state.items, (item) => {
                const id = item.variation.id
                  ? item.variation.id
                  : item.product.id
                const type = item.variation.id
                  ? 'App\\Models\\Variation'
                  : 'App\\Models\\Product'
                if (e.item_id == id && e.item_type == type) {
                  store.commit('setItemUUID', {
                    item: item,
                    uuid: e.id,
                  })
                }
              })
            })
            EventBus.$emit('cartSyncDone')
          },
          (e) => {
            console.error(e)
          },
        )
      })
    },
    async getServerTotals(store, email) {
      return new Promise((resolve, reject) => {
        store.dispatch('getCartId').then((cartid) => {
          this.commit('cart/totalsPending', true)
          $axios({
            method: 'get',
            url: process.env.VUE_APP_VAVE_URL + '/api/carts/' + cartid + '/total',
            withCredentials: true,
            params: { email: email },
          }).then(
            (r) => {
              this.commit('cart/setServerTotals', r.data)
              this.commit('cart/totalsPending', false)
              resolve(r)
            },
            (e) => {
              console.error(e)
              reject(e);
            }
          )
        })
      })
    },
    async removeCoupon(store) {
      return new Promise((resolve, reject) => {
        store.dispatch('getCartId').then((cartid) => {
          $axios({
            method: 'delete',
            url:
              process.env.VUE_APP_VAVE_URL +
              '/api/carts/' +
              cartid +
              '/coupons',
            withCredentials: true,
          }).then(
            () => {
              this.commit('cart/couponId', null)
              this.commit('cart/removeCoupon', null)
              this.commit('cart/couponApplied', false)

              this.commit(
                'app/addNotification',
                {
                  type: 'good',
                  title: 'Coupon Removed',
                  message: 'Your coupon has been removed successfully',
                  re: 'coupon',
                  icon: 'check',
                },
                { root: true },
              )

              store.dispatch('getServerTotals', null).finally((r) => {
                resolve(r)
              })
            },
            (e) => {
              console.error(e)
              reject(e)
            }
          )
        })
      })
    },
    async setCoupon(store, data) {
      return new Promise((resolve, reject) => {
        let coupon = data.coupon;
        let email = data.email;

        if (coupon == null) {
          store.commit(
            'app/addNotification',
            {
              type: 'warn',
              title: 'Coupon required',
              message: 'The coupon field is required',
              re: 'coupon',
            },
            { root: true },
          );
          reject();
        }
        store.dispatch('getCartId').then((cartid) => {
          $axios({
            method: 'post',
            url:
              process.env.VUE_APP_VAVE_URL +
              '/api/carts/' +
              cartid +
              '/coupons',
            withCredentials: true,
            data: {
              coupon: coupon,
            },
          }).then(
            (r) => {
              if (r.data.status == 'ineligible') {
                store.commit('setCouponMessage', r.data.message)
                store.commit(
                  'app/addNotification',
                  {
                    type: 'warn',
                    title: 'Coupon Pending',
                    message: r.data.message,
                    re: 'coupon',
                  },
                  { root: true },
                )
                store.commit('couponApplied', false)
              } else {
                store.commit('setCouponMessage', null)

                if (!store.state.coupon.applied) {
                  store.commit(
                    'app/addNotification',
                    {
                      type: 'good',
                      title: 'Coupon Applied',
                      message: 'Your coupon has been applied successfully',
                      re: 'coupon',
                      icon: 'check',
                    },
                    { root: true },
                  )
                }
                store.commit('couponId', r.data.coupon.id)
                store.commit('couponApplied', true)
              }

              store.commit('setCoupon', coupon)
              store.dispatch('getServerTotals', email).finally(() => {
                resolve(r)
              })
            },
            (e) => {
              if (e.response.data.status == 'coupon_not_found') {
                store.commit('couponApplied', false)
                EventBus.$emit('invalidCoupon')
                this.commit(
                  'app/addNotification',
                  {
                    type: 'bad',
                    title: 'Invalid Coupon',
                    message:
                      "Sorry, we don't recogognise that coupon. Please check and try again!",
                    re: 'coupon',
                    icon: 'circleExclamation',
                    pulse: true,
                  },
                  { root: true },
                )
              } else if (e.response.data.status == 'invalid') {
                store.commit('couponApplied', false)
                EventBus.$emit('invalidCoupon')
                this.commit(
                  'app/addNotification',
                  {
                    type: 'bad',
                    title: 'Invalid Coupon',
                    message:
                      e.response.data.message,
                    re: 'coupon',
                    icon: 'circleExclamation',
                    pulse: true,
                  },
                  { root: true },
                )
              } else {
                var settingCoupon = function () {
                  EventBus.$off('cartSyncDone', settingCoupon)
                  store.dispatch('setCoupon', coupon)
                }
                EventBus.$on('cartSyncDone', settingCoupon)
              }
              reject(e)
            },
          )
        })
      })
    },
    async deleteFromCart(store, sku) {
      var existing = false

      return new Promise((resolve, reject) => {
        store.state.items.forEach((item) => {
          if (item.sku == sku) {
            this.commit('cart/deleteItem', item)
            EventBus.$emit('cart.item.removed')
            resolve()
          }
        })
        if (!existing) {
          reject(new Error('Item not found'))
        }
      })
    },
    async syncItemsProduct(store) {
      // An item contains a copy of a product (+variation).
      // If the product price is changed, we need to reflect
      // that change in the Item.
      store.state.items.forEach((item) => {
        // We fetch a fresh copy of the product from the server
        var url = process.env.VUE_APP_VAVE_URL
        var productId = item.product.id
        $axios.get(url + '/api/products/' + productId).then((response) => {
          if (response.data.data) {
            var payload = {
              item: item,
              product: response.data.data,
            }
            if (!item.variation.id) {
              store.commit('updateProductItem', payload)
              return
            }
            // Fetch also the Variations for this product from the server
            $axios
              .get(url + '/api/products/' + productId + '/variations')
              .then((response) => {
                if (response.data.data) {
                  payload.variation = response.data.data.find(
                    (variation) => variation.id == item.variation.id,
                  )
                  store.commit('updateProductItem', payload)
                }
              })
          }
        })
      })
    },
    async increaseCartQty(store, sku) {
      var existing = false

      return new Promise((resolve, reject) => {
        store.state.items.forEach((item) => {
          if (item.sku == sku) {
            const instock = item.variation.id
              ? item.variation.stock_quantity
              : item.product.stock_quantity
            if (item.quantity < instock) {
              this.commit('cart/setQty', {
                item: item,
                qty: parseInt(item.quantity) + 1,
              })
              existing = true
              EventBus.$emit('cart.qty.increased', { item })
              resolve()
            } else {
              reject(
                new Error(
                  'You are trying to add more items than we have in stock.',
                ),
              )
            }
          }
        })
        if (!existing) {
          reject(new Error('Item not found'))
        }
      })
    },
    async decreaseCartQty(store, sku) {
      var existing = false
      return new Promise((resolve, reject) => {
        store.state.items.forEach((item) => {
          if (item.sku == sku) {
            if (item.quantity > 0) {
              this.commit('cart/setQty', {
                item: item,
                qty: parseInt(item.quantity) - 1,
              })
              EventBus.$emit('cart.qty.decreased', { item })
              existing = true
              resolve()
            } else {
              reject(new Error('No more items to remove'))
            }
          }
        })
        if (!existing) {
          reject(new Error('Item not found'))
        }
      })
    },
    async addToCart(store, payload) {
      return new Promise((resolve, reject) => {
        const instock = payload.variation.id
          ? payload.variation.stock_quantity
          : payload.product.stock_quantity
        var existing = false
        var qty
        if (payload.quantity < 0) {
          qty = 0
        } else {
          qty = payload.quantity
        }
        if (payload.isMobileApp == true) {
          var object = {
            name: payload.product.name,
            variationName: payload.variation.name,
            quantity: payload.quantity,
            productId: payload.product.id,
            variationId: payload.variation.id,
            price: payload.variation.id ? payload.variation.price : payload.product.price,
            image: payload.variation.id ? payload.variation.image[0]["origin_src"] : payload.product.images[0]["origin_src"],
            stock: payload.variation.id ? payload.variation.stock_quantity : payload.product.stock_quantity,
          };
          // eslint-disable-next-line
          AddToCartChannel.postMessage(JSON.stringify(object));
          resolve(true);
        }

        store.state.items.forEach((item) => {
          if (item.sku == payload.sku) {
            if (parseInt(qty) + parseInt(item.quantity) <= instock) {
              const newQty = parseInt(item.quantity) + parseInt(qty)
              this.commit('cart/setQty', { item: item, qty: newQty })
              EventBus.$emit('cart.qty.increased', { item })
              resolve(true)
            } else {
              reject(
                new Error(
                  'You are trying to add more items than we have in stock.',
                ),
              )
            }
            existing = true
          }
        })
        if (!existing) {
          if (qty <= instock) {
            const item = {
              product: payload.product,
              variation: payload.variation,
              quantity: qty,
              sku: payload.sku,
              uuid: null,
            }

            if (payload.debounced === true) {
              store.commit('addItem', item)
            } else {
              store.commit('addItemUndebounced', item)
            }

            EventBus.$emit('cart.item.added', {
              item,
            })

            resolve(true)
          } else {
            reject(
              new Error(
                'You are trying to add more items than we have in stock.',
              ),
            )
          }
        }
      })
    },
  },
}
