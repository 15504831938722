import Vue from 'vue';
import axios from './http'
import searchEvent from "./events/eventTypes/search";
import debounce from './debounce'

export default new Vue({

    data() {
        return {
            searchQuery: null,
            results: [],
            debounced: debounce((r) => {
                // Fire an event to log how many results were returned for this query
                new searchEvent(this.searchQuery, r.data.meta.total).dispatch();
            }, 500),
        };
    },

    methods: {
        setQuery(query) {
            this.searchQuery = query;
        },
        search() {
            return new Promise((resolve) => {
                axios.get(
                    process.env.VUE_APP_VAVE_URL +
                    "/api/products/search?q=" +
                    this.searchQuery
                ).then((r) => {
                    this.results = r.data.data;
                    this.debounced(r)
                    resolve(r)
                });
            });
        },
    },

    watch: {
        searchQuery() {
            if (this.searchQuery.length >= 3) {
                this.search();
            } else {
                this.results = [];
            }
        },
        results: {
            deep: true,
            handler() {
                this.$emit('results', this.results);
            }
        }
    },

});