import Vue from 'vue'
import ukphone from './validators/ukphone'
import isEmail from './validators/emailAddress'

export default new Vue({
    methods: {
        email(data) {
            return new Promise((resolve, reject) => {
                if (isEmail(data)) {
                    resolve();
                } else {
                    reject()
                }
            })
        },
        phone(data) {
            return new Promise((resolve, reject) => {
                if (!ukphone(data)) {
                    reject()
                } else {
                    resolve()
                }
            })
        },
        postcode(data) {
            return new Promise((resolve, reject) => {
                // TODO check with the list of postcodes
                if (/^([A-Z][A-HJ-Y]?\d[A-Z\d]?\s*[\d][A-Z]{2}|[GIR]\s*?0[A]{2})$/.test(data)) { resolve() } else { reject() }
            })
        }
    }
})