<template>
  <div :class="{themeDark:dark}">
    <a :href="'mailto:' + email" class="mailto" @click="track">{{label}}</a>
  </div>
</template>

<script>
import clicktoEmailEvent from '../../events/eventTypes/clickToEmail';
export default {
  props: ["email", "label", "dark"],
  methods: {
    track() {
      new clicktoEmailEvent(this.email).dispatch();
    }
  }
};
</script>


<style lang="scss" scoped>
.themeDark {
  a.mailto {
    &:hover {
      color: white !important;
    }
  }
}
</style>