
export default {
    props: {
        label: {
            type: String,
            default: 'Button'
        },
        disabled: false,
        loading: false,
        notab: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {

        }
    },

    methods: {
        handleTab(e) {
            if(this.notab) {
                console.log('Attempting to prevent tab');
                e.preventDefault();
                e.stopPropagation();
            }
        },
        click(event) {
            this.$emit('click', event)
        }
    }

}