import tracker from "../tracker";

export default class paymentFailed {

  constructor(orderId, provider, error, amount = null) {
    this.orderId = orderId;
    this.provider = provider;
    this.error = error;
    this.amount = amount;
  }

  dispatch() {
    tracker.publish(this)
  }
}