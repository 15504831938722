import tracker from "../tracker";

export default class beginCheckout {

  constructor(cartId, lines, shipping, total) {
    this.cartId = cartId;
    this.shipping = shipping;
    this.total = total;
    var products = [];
    lines.forEach(line => {
      var product = {
        id: line.product.sku,
        name: line.product.name,
        brand: line.product.brand,
        price: line.product.price,
        quantity: line.quantity,
      }

      if (line.variation.id) {
        product.id = line.variation.sku
        product.name = product.name + ' ' + line.variation.name;
        product.price = line.variation.price;
      }

      products.push(product);
    })

    this.products = products;
  }

  dispatch() {
    tracker.publish(this)
  }
}