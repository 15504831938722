import tracker from "../tracker";

export default class orderProducts {

  constructor(orderBy) {
    this.orderBy = orderBy;
  }

  dispatch() {
    tracker.publish(this)
  }
}