import tracker from "../tracker";

export default class search {

  constructor(query, results) {
    this.query = query;
    this.results = results;
  }

  dispatch() {
    tracker.publish(this)
  }
}