import tracker from "../tracker";

export default class paymentSucceeded {

  constructor(orderId, provider, amount = null, data = {}) {
    this.orderId = orderId;
    this.provider = provider;
    this.amount = amount
    this.data = data
  }

  dispatch() {
    tracker.publish(this)
  }
}