<template>
  <div
    class="search"
    ref="me"
    @keyup.enter="navigate"
  >
    <icon type="searchSolid" class="search-icon" width="18px" height="18px" />
    <input
      type="text"
      class="searchbar"
      v-model="query"
      aria-label="search bar"
      @keyup.esc="forceHidden = true"
      @click="forceHidden = false"
      spellcheck="false"
    />
    <div
      class="results"
      v-if="this.$store.state.app.productsLoaded"
      :class="{ showing: showResults }"
      ref="results"
    >
      <div
        class="result"
        v-for="(product, index) in results"
        :key="product.id"
        :ref="'ix' + index"
      >
        <result :product="product"></result>
      </div>
    </div>
  </div>
</template>


<script>
import result from "../catalogue/productSearchResult";
import search from "../../search";

export default {
  mounted() {
    this.getQ();
    search.$on("results", (results) => {
      this.results = results;
    });
  },

  components: {
    result,
  },

  methods: {
    getQ() {
      var params = new URLSearchParams(window.location.search);
      var q = params.get("q");
      if (q) {
        this.query = q;
      }
    },
    navigate() {
      if(this.selectedIndex == -1) {
        // If the query is not changed, let's not redirect the user
        var params = new URLSearchParams(window.location.search);
        var q = params.get("q");
        if (this.query == q) {
          return;
        }
        this.$router.push({
          name: "search",
          query: {q: this.query}
        });
        return
      }
      const product = this.results[this.selectedIndex];
      
      if (product) {
        this.selectedIndex = -1;
        this.$router.push({
          name: "showProduct",
          params: { product: product.slug, productName: product.name },
        });
      }
    },
    clickOutside(event) {
      if (!this.$refs.me.contains(event.target)) {
        this.forceHidden = true;
      }
    },
  },

  data() {
    return {
      query: "",
      results: [],
      forceHidden: false,
      selectedIndex: -1,
    };
  },

  watch: {
    selectedIndex() {
      if (this.selectedIndex > this.results.length - 1) {
        this.selectedIndex = this.results.length - 1;
      }

      if (this.selectedIndex < 0) {
        this.selectedIndex = 0;
      }

      const el = this.$refs["ix" + this.selectedIndex][0];
      el.scrollIntoView(false, { behavior: "smooth" });
    },

    showResults(showing) {
      if (showing) {
        document.addEventListener("click", this.clickOutside);
      } else {
        document.removeEventListener("click", this.clickOutside);
      }
    },

    query() {
      this.selectedIndex = -1;
      search.setQuery(this.query);
      this.forceHidden = false;
    },

    $route() {
      this.getQ();
      this.forceHidden = true;
    },
  },

  computed: {
    showResults() {
      if (this.results.length == 0) {
        return false;
      }
      if (this.forceHidden) {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  // background-color: white;
  width: 100%;
  position: relative;
}
.search-icon {
  float: left;
  width: 40px;
  background-color: white;
  height: 38px;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-search-icon {
  width: 40px;
  color: white;
  height: 38px;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}
.searchbar {
  background-color: white;
  border: none;
  width: calc(100% - 40px);
  font-size: 1.2em;
  border-radius: 0 4px 4px 0;
  height: 38px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.results {
  position: absolute;
  top: calc(100% + 1px);
  left: 20px;
  width: calc(100% - 40px);

  background-color: rgba(white, 0.95);
  border: 5px solid white;
  min-height: 300px;
  z-index: 100;
  border-radius: 5px 5px 5px 5px;
  filter: drop-shadow(0px 10px 4px rgba(black, 0.3));
  max-height: 0px;
  overflow-y: auto;
  overflow-x: hidden;

  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease-in-out;

  &.showing {
    max-height: 400px;
    opacity: 1;
    pointer-events: unset;
  }
}
</style>