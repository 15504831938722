<template>
  <div v-if="!scrollable">
    <div class="container">
      <div class="row align-items-top" :class="{'flex-column': layout=='column'}" v-if="$store.state.app.productsLoaded">
        <div :class="'col-12 col-md-'+itemsCount" v-for="(product, index) in sortedProducts" :key="index">
          <product-list-item :product="product" :index="index" :class="{'product': layout=='column'}" :showPrice="showPrice" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="scrollable" @mousemove="mouseMove" ref="scrollable" @mouseup="mouseUp" @mousedown="mouseDown" @click.prevent>
    <div class="force-col-mobile col-auto" v-for="(product, index) in sortedProducts" :key="index">
      <product-list-item :product="product" :index="index" class="product" :showPrice="showPrice" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import productListItem from "../components/catalogue/productListItem.vue";
import productFilter from "../productFilter";
import orderProducts from "./../orderProducts";

export default {
  props: {
    layout: {
      type: String,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
    },
    maxItems: {
      default: 4,
    },
    brands: {
      type: Array,
      default() {
        return [];
      },
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    ids: {
      type: Array,
      default() {
        return [];
      },
    },
    slugs: {
      type: Array,
      default() {
        return [];
      },
    },
    exclude: {
      type: Number,
    },
    showPrice: {
      type: Boolean,
      default: false,
    },
    strategy: {
      type: String,
      default: "_default",
    },
  },
  components: {
    productListItem,
  },
  data() {
    return {
      filters: {
        brands: [],
        coatings: [],
        price: [0, 50],
        categories: [],
        ids: [],
        slugs: [],
      },
    };
  },
  mounted() {
    this.filters.brands = [...this.brands];
    if (this.categories) {
      this.filters.categories = [...this.categories];
    }
    if (this.ids) {
      this.filters.ids = [...this.ids];
    }
    if (this.slugs) {
      this.filters.slugs = [...this.slugs];
    }
  },
  watch: {
    ids: {
      deep: true,
      handler() {
        if (this.ids) {
          this.filters.ids = [...this.ids];
        }
      },
    },
    slugs: {
      deep: true,
      handler() {
        if (this.slugs) {
          this.filters.slugs = [...this.slugs];
        }
      },
    },
  },
  methods: {
    mouseDown(e) {
      const t = this.$refs.scrollable;
      this.left = t.scrollLeft;
      this.right = t.scrollTop;
      this.mx = e.clientX;
      this.my = e.clientY;
      this.dragging = true;
      this.dragged = false;
    },
    mouseMove(e) {
      if (this.dragging) {
        const t = this.$refs.scrollable;

        const detlaX = e.clientX - this.mx;
        const detlaY = e.clientY - this.my;

        t.scrollTop = this.top - detlaY;
        t.scrollLeft = this.left - detlaX;
        this.dragged = true;
        t.classList.add("noclick");
      }
    },
    mouseUp(e) {
      const t = this.$refs.scrollable;
      if (this.dragged) {
        e.preventDefault();
        e.stopPropagation();
      }
      this.dragging = false;
      this.dragged = false;
      t.classList.remove("noclick");
    },
  },
  computed: {
    itemsCount: function () {
      if (this.sortedProducts.length <= 2) {
        return 6;
      }
      if (this.sortedProducts.length <= 3) {
        return 4;
      }
      return 3;
    },
    sortedProducts: function () {
      let orderMap = new Map();
      orderMap.set("price_asc", "pricel2h");
      orderMap.set("price_desc", "priceh2l");
      orderMap.set("strategy", "recommended");

      return orderProducts(
        this.products,
        orderMap.get(this.order),
        this.strategy
      );
    },
    products() {
      if (this.order === "random") {
        return shuffle(this.processExcludes).slice(0, this.maxItems);
      }
      return this.processExcludes.slice(0, this.maxItems);
    },
    processExcludes() {
      return productFilter.products(this.filters).filter((product) => {
        return product.id !== this.exclude;
      });
    },
  },
};

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
</script>

<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";
.force-col-mobile {
  @include media-breakpoint-down(md) {
    flex: 0 0 auto !important;
  }
}

.product {
  width: 300px;
  margin-right: 10px;
  @include media-breakpoint-up(md) {
    width: 250px;
    margin-right: 10px;
  }
}
.scrollable {
  cursor: grab;
  overflow: auto;
  display: flex;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.noclick {
    .vave-button {
      pointer-events: none;
    }
  }
}
</style>