<template>
  <div class="vave-nav-container d-none d-md-block">
    <div class="container-fluid">
      <div class="row h-100">
        <div class="col">
          <nav class="vave-nav" ref="nav">
            <item v-for="item, index in items" :key="index" @activated="moveMark($event)" :item="item" />
            <div class="tp">
              <div class="tp-wrapper">
                <trust-pilot template="5419b6a8b0d04a076446a9ad" theme="dark"></trust-pilot>
              </div>
            </div>
            <div class="marker" ref="mark"></div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import mmItem from "./megaMenuItem";
// import filteredProducts from "../filteredProducts.vue";
import trustPilot from "../trustPilot";
import item from "./navItems/item.vue";

export default {
  data() {
    return {
      lastPos: 0,
      items: [
        {
          label: "New<br />Arrivals",
          to: {
            name: "category",
            params: { category: "New Products", react: true },
          },
          cols: [],
        },
        // {
        //   label: "BLACK<br />FRIDAY",
        //   to: "/black-friday-sale-event",
        //   cols: [],
        //   colour: "special",
        //   featuredSlugs: [],
        // },
        {
          label: "All<br />Products",
          featuredSlugs: ["riley-navigator", "alpha-solway-ix-3"],
          to: {
            name: "products",
          },
          cols: [
            // {
            //   heading: "Categories",
            //   links: [
            //     {
            //       anchor: "Hand Protection",
            //       to: {
            //         name: "category",
            //         params: { category: "Hand Protection", react: true },
            //       },
            //     },
            //     {
            //       anchor: "Eye Protection",
            //       to: {
            //         name: "category",
            //         params: { category: "Eye Protection", react: true },
            //       },
            //     },
            //     {
            //       anchor: "Respiratory Protection",
            //       to: {
            //         name: "category",
            //         params: {
            //           category: "Respiratory Protection Equipment",
            //           react: true,
            //         },
            //       },
            //     },
            //     {
            //       anchor: "Hearing Protection",
            //       to: {
            //         name: "category",
            //         params: { category: "Hearing Protection", react: true },
            //       },
            //     },
            //     {
            //       anchor: "Head Protection",
            //       to: {
            //         name: "category",
            //         params: { category: "Head Protection", react: true },
            //       },
            //     },
            //   ],
            // },
            {
              heading: "Collections",
              links: [
                {
                  anchor: "Eco Range",
                  to: "sustainability-range",
                },
                {
                  anchor: "Gardening Essentials",
                  to: {
                    name: "category",
                    params: { category: "Gardening Essentials", react: true },
                  },
                },
                {
                  anchor: "DIY Essentials",
                  to: "/products/industries/DIY",
                },
                {
                  anchor: "Sports",
                  to: "/riley-sports",
                },
                {
                  anchor: "Thermal",
                  to: {
                    name: "category",
                    params: { category: "Thermal Gloves", react: true },
                  },
                },
                // {
                //   anchor: "Single Use",
                //   to: {
                //     name: "category",
                //     params: { category: "Single Use", react: true },
                //   },
                // },
              ],
            },
          ],
        },
        {
          label: "Hand<br />Protection",
          to: {
            name: "category",
            params: { category: "Hand Protection", react: true },
          },
          cols: [
            {
              heading: "Categories",
              links: [
                {
                  anchor: "Multi-Purpose Gloves",
                  to: {
                    name: "category",
                    params: { category: "Multi-Purpose", react: true },
                  },
                },
                {
                  anchor: "Single-Use Gloves",
                  to: {
                    name: "category",
                    params: { category: "Single Use", react: true },
                  },
                },
                {
                  anchor: "Grip Gloves",
                  to: {
                    name: "category",
                    params: { category: "Grip Gloves", react: true },
                  },
                },
                {
                  anchor: "Puncture &amp; Cut Protection Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Puncture and Cut Protection Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Chemical Protection Gloves",
                  to: {
                    name: "category",
                    params: {
                      category:
                        "Chemical Handling and Chemical Resistant Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Thermal Gloves",
                  to: {
                    name: "category",
                    params: { category: "Thermal Gloves", react: true },
                  },
                },
              ],
            },
            {
              heading: "&nbsp;",
              links: [
                {
                  anchor: "Impact Protection Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Impact Resistant Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Waterproof Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Waterproof Working Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Heat-resistant Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Heat Resistant Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Lightweight Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Lightweight Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Touchscreen Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Touchscreen Gloves",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Gardening Gloves",
                  to: {
                    name: "category",
                    params: {
                      category: "Gardening Gloves",
                      react: true,
                    },
                  },
                },
              ],
            },
            // {
            //   heading: "Collections",
            //   links: [
            //     // {
            //     //   anchor: "Eco Range",
            //     //   to: "sustainability-range",
            //     // },
            //     // {
            //     //   anchor: "Gardening",
            //     //   to: {
            //     //     name: "category",
            //     //     params: { category: "Gardening Essentials", react: true },
            //     //   },
            //     // },
            //     // {
            //     //   anchor: "DIY",
            //     //   to: "/products/industries/DIY",
            //     // },
            //     // {
            //     //   anchor: "Disposable",
            //     //   to: {
            //     //     name: "category",
            //     //     params: { category: "Single Use", react: true },
            //     //   },
            //     // },
            //   ],
            // },
          ],
          featuredSlugs: ["ninja-flex", "showa-310-orange"],
        },
        {
          label: "Eye<br />Protection",
          to: {
            name: "category",
            params: { category: "Eye Protection", react: true },
          },
          cols: [
            {
              heading: "Categories",
              links: [
                {
                  anchor: "Safety Glasses",
                  to: {
                    name: "category",
                    params: {
                      category: "Eye Protection",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Safety Goggles",
                  to: {
                    name: "category",
                    params: {
                      category: "Safety Goggles",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Sporty Safety Glasses",
                  to: {
                    name: "category",
                    params: {
                      category: "Sporty Safety Glasses",
                      react: true,
                    },
                  },
                },
              ],
            },
            {
              heading: "Features",
              links: [
                {
                  anchor: "Polarised",
                  to: {
                    name: "category",
                    params: {
                      category: "Polarised Safety Glasses",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Anti-Fog",
                  to: {
                    name: "category",
                    params: {
                      category: "Anti Fog Safety Glasses",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Anti-Scratch",
                  to: {
                    name: "category",
                    params: {
                      category: "Anti Scratch Safety Glasses",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Wrap Around",
                  to: {
                    name: "category",
                    params: {
                      category: "Wrap Around Safety Glasses",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Light Weight",
                  to: {
                    name: "category",
                    params: {
                      category: "Lightweight Safety Glasses",
                      react: true,
                    },
                  },
                },
              ],
            },
            {
              heading: "Collections",
              links: [
                {
                  anchor: "Riley Sports",
                  to: "/riley-sports",
                },
              ],
            },
          ],
          featuredSlugs: ["stream-red", "riley-navigator"],
        },
        {
          label: "Respiratory<br />Protection",
          to: {
            name: "category",
            params: {
              category: "Respiratory Protection Equipment",
              react: true,
            },
          },
          cols: [
            {
              heading: "Categories",
              links: [
                {
                  anchor: "Type IIR",
                  to: {
                    name: "category",
                    params: {
                      category: "Type IIR, Surgical Mask",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "FFP2",
                  to: {
                    name: "category",
                    params: {
                      category: "FFP2",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "FFP3",
                  to: {
                    name: "category",
                    params: {
                      category: "FFP3",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Face Coverings",
                  to: {
                    name: "category",
                    params: {
                      category: "Reusable Face Coverings",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Clear Face Masks",
                  to: {
                    name: "category",
                    params: {
                      category: "Transparent Face Masks",
                      react: true,
                    },
                  },
                },
              ],
            },
            {
              heading: "Collections",
              links: [
                {
                  anchor: "UK Made",
                  to: "/best-of-british",
                },
              ],
            },
          ],
          featuredSlugs: [
            "haika-mx-uk-made",
            "riley-performance-face-covering",
          ],
        },
        {
          label: "Head & Hearing<br />Protection",
          to: {},
          cols: [
            {
              heading: "Categories",
              links: [
                {
                  anchor: "Head Protection",
                  to: {
                    name: "category",
                    params: {
                      category: "Head Protection",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Hearing Protection",
                  to: {
                    name: "category",
                    params: {
                      category: "Hearing Protection",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Heavy Duty Ear Defenders",
                  to: {
                    name: "category",
                    params: {
                      category: "Heavy Duty Ear Defenders",
                      react: true,
                    },
                  },
                },
                {
                  anchor: "Lightweight Ear Defenders",
                  to: {
                    name: "category",
                    params: {
                      category: "Lightweight Ear Defenders",
                      react: true,
                    },
                  },
                },
              ],
            },
          ],
          featuredSlugs: ["alpha-sota-m2", "enha-syntis-dynamic"],
        },
        // {
        //   label: "Shop by Brand",
        //   to: {
        //     name: "products",
        //   },
        //   cols: [
        //     {
        //       heading: "Brands",
        //       links: [
        //         {
        //           anchor: "Skytec",
        //           to: {
        //             name: "brand",
        //             params: {
        //               brand: "Skytec",
        //               react: true,
        //             },
        //           },
        //         },
        //         {
        //           anchor: "Showa",
        //           to: {
        //             name: "brand",
        //             params: {
        //               brand: "Showa",
        //               react: true,
        //             },
        //           },
        //         },
        //         {
        //           anchor: "ENHA",
        //           to: {
        //             name: "brand",
        //             params: {
        //               brand: "ENHA",
        //               react: true,
        //             },
        //           },
        //         },
        //         {
        //           anchor: "Haika",
        //           to: {
        //             name: "brand",
        //             params: {
        //               brand: "Haika",
        //               react: true,
        //             },
        //           },
        //         },
        //         {
        //           anchor: "Riley",
        //           to: {
        //             name: "brand",
        //             params: {
        //               brand: "Riley Eyewear",
        //               react: true,
        //             },
        //           },
        //         },
        //       ],
        //     },
        //   ],
        //   featuredSlugs: [],
        //   featuredImages: [
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1631713800/brands/M%20Store.jpg",
        //       to: "/products",
        //     },
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1631713726/brands/Skytec.jpg",
        //       alt: "Skytec",
        //       to: "/products/brands/skytec",
        //     },
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1631713699/brands/SHOWA.jpg",
        //       to: "/products/brands/SHOWA",
        //     },
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1681745336/brands/centered_enha_dbcjct.webp",
        //       to: "/products/brands/ENHA",
        //     },
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1631713778/brands/HAIKA.jpg",
        //       to: "/products/brands/haika",
        //     },
        //     {
        //       src: "https://res.cloudinary.com/mstore/image/upload/v1676289903/brands/Riley%20Eyewear.jpg",
        //       to: "/products/brands/riley eyewear",
        //     },
        //   ],
        // },
        // {
        //   label: "Healthcare",
        //   to: "/healthcare",
        //   colour: "blue",
        //   cols: [],
        //   featuredSlugs: [],
        // },
         {
          label: "Industries",
          to: {},
          cols: [
            {
              heading: "Industries",
              links: [
                {
                  anchor: "Cleaning",
                  to: "/cleaning-industry",
                },
                {
                  anchor: "Health & Beauty",
                  to: "/health-and-beauty-industry",
                },
                {
                  anchor: "Healthcare",
                  to: "/healthcare",
                },
                {
                  anchor: "Construction",
                  to: "/construction",
                },
              ],
            },
          ],
          featuredSlugs: ["skytec-tx424", "riley-stream-evo-small-eyewear"],
        },
        {
          label: "CLEARANCE",
          to: "/clearance",
          colour: "special",
          cols: [],
          featuredSlugs: [],
        },
        
       
      ],
    };
  },
  methods: {
    moveMark(event) {
      const Elrect = event.$el.getBoundingClientRect();
      const NavRect = this.$refs.nav.getBoundingClientRect();
      let left = Elrect.x - NavRect.x;
      let right = left + Elrect.width;
      let navright = NavRect.width;
      if (left < this.lastPos) {
        this.$refs.mark.classList.add("goingLeft");
        this.$refs.mark.classList.remove("goingRight");
      } else {
        this.$refs.mark.classList.add("goingRight");
        this.$refs.mark.classList.remove("goingLeft");
      }
      this.$refs.mark.style.left = left + "px";
      this.$refs.mark.style.right = navright - right + "px";
      this.lastPos = left;
    },
  },
  components: {
    trustPilot,
    item,
  },
};
</script>

<style lang="scss" scoped>
.vave-nav-container {
  background-color: #363738;
}

.vave-nav {
  display: flex;
  flex-direction: row;
  position: relative;

  a {
    color: white;
    text-decoration: none;
  }
}

.tp {
  flex-grow: 1;

  .tp-wrapper {
    padding-top: 22px;
  }
}

.marker {
  pointer-events: none;
  background-color: #feb103;
  height: 3px;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 100%;
  left: -1000px;
  right: 1000px;
  z-index: 2;

  &.goingRight {
    transition: right 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
      left 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-delay: 0ms, 250ms;
    transition-property: right, left;
  }

  &.goingLeft {
    transition: left 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
      right 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-delay: 0ms, 250ms;
    transition-property: left, right;
  }
}
</style>