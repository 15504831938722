import tracker from "../tracker";

export default class clickToCall {

  constructor(number) {
    this.number = number
  }

  dispatch() {
    tracker.publish(this)
  }
}