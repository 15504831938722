import tracker from "../tracker";

export default class failedCheckout {

  constructor(orderId, error) {
    this.orderId = orderId;
    this.error = error;
  }

  dispatch() {
    tracker.publish(this)
  }
}