import newsletterSubscribe from '../events/eventTypes/newsletterSubscribe'
import registerEvent from '../events/eventTypes/register'
import axios from '../http'
import * as Sentry from '@sentry/browser';


export default {
  namespaced: true,
  state: () => ({
    user: {},
    stickyId: null,
    referralClicked: null,
    availableCredit: null,
    canUseCreditToPay: false,
    minimumSpendToUseCredit: null,
    previousSucceededOrdersAmount: null,
    userCards: [],
  }),
  getters: {
    userIsLogged(state) {
      return state.user && state.user.id
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserCards(state, userCards) {
      state.userCards = userCards
    },
    setStickyId(state, stickyId) {
      state.stickyId = stickyId
      try {
        if (stickyId) {
          Sentry.configureScope((scope) => {
            scope.setTag('sticky_id', stickyId);
          });
        }
      } catch (e) {
        // Sentry is not initialized
        console.log(e)
      }
    },
    setReferralClicked(state, code) {
      state.referralClicked = code
    },
    setAvailableCredit(state, amount) {
      state.availableCredit = amount;
    },
    setMinimumSpendToUseCredit(state, amount) {
      state.minimumSpendToUseCredit = amount;
    },
    setPreviousSucceededOrdersAmount(state, amount) {
      state.previousSucceededOrdersAmount = amount;
    },
    setCanUseCreditToPay(state, value) {
      state.canUseCreditToPay = value;
    },
  },

  actions: {
    logout() {
      this.commit('auth/setUser', {})
      this.commit('auth/setUserCards', {})
      return new Promise((resolve) => {
        axios
          .post(
            process.env.VUE_APP_VAVE_URL + '/logout',
            {},
            { withCredentials: true },
          )
          .then(
            (r) => {
              if (r.status == 200) {
                resolve(r)
              }
            },
            (e) => {
              // User is already logged out, let's resolve the promise
              resolve(e.response)
            },
          )
      })
    },
    syncUserCards() {
      if (this.state.auth.user.id == null) {
        console.log("user not logged in, cannot retrieve cards");
        return;
      }
      axios({
        method: "get",
        url: process.env.VUE_APP_VAVE_URL + "/api/payment-methods",
        withCredentials: true,
      })
        .then((r) => {
          this.commit('auth/setUserCards', r.data.payment_methods);
        });
    },
    checkUserIsLoggedIn() {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_VAVE_URL + '/api/users/me', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          })
          .then(
            (r) => {
              if (r.status == 200) {
                // User is actually logged in, let's override the user data in the store
                this.commit('auth/setUser', r.data.user)
                this.commit(
                  'app/setRegisteredToNewsletter',
                  r.data.user.marketing_opt_in,
                )
                resolve(r.data.user)
              }
            },
            (e) => {
              if (e.response.status == 401) {
                // There is a problem with user cookies
                reject(e)
              }
            },
          )
      })
    },
    checkUserCredit() {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_VAVE_URL + '/api/users/me/credit', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          })
          .then(
            (r) => {
              if (r.status == 200) {
                // User is actually logged in, let's override the user data in the store
                this.commit('auth/setCanUseCreditToPay', r.data.can_use_credit_to_pay)
                this.commit('auth/setAvailableCredit', r.data.available_credit)
                this.commit('auth/setMinimumSpendToUseCredit', r.data.minimum_spend_to_use_credit_in_cents);
                this.commit('auth/setPreviousSucceededOrdersAmount', r.data.previous_succeeded_orders_amount_in_cents);
                resolve(r.data)
              }
            },
            (e) => {
              if (e.response.status == 401) {
                // There is a problem with user cookies
                reject(e)
              }
            },
          );
      });
    },
    referralCodeClick(context, code) {
      if (this.state.auth.referralClicked == code) {
        return;
      }
      return new Promise(() => {
        axios.post(process.env.VUE_APP_VAVE_URL + '/api/referrals/' + code + '/click').then(() => {
          this.commit('auth/setReferralClicked', code)
        })
      })
    },
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_VAVE_URL + '/login',
            {
              email: credentials.email,
              password: credentials.password,
            },
            {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            },
          )
          .then(
            (r) => {
              if (r.status == 200) {
                resolve(r)
              }
            },
            (e) => {
              reject(e)
            },
          )
      })
    },
    register(context, credentials) {
      return new Promise((resolve, reject) => {
        new registerEvent().dispatch();
        axios
          .post(
            process.env.VUE_APP_VAVE_URL + '/register',
            {
              type: credentials.type,
              name: credentials.first_name + ' ' + credentials.last_name,
              email: credentials.email,
              password: credentials.password,
              referral_code: credentials.referral_code,
              password_confirmation: credentials.password,
              marketing_opt_in: credentials.marketing_opt_in,
              terms_accepted: credentials.terms_accepted,
            },
            {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            },
          )
          .then(
            (r) => {
              if (r.status == 201) {
                resolve(r)
              }
              let e = new Error('Unexpected error')
              e.response = r
              if (r.status == 200) {
                // User was already logged in
                e.message = 'User already logged in'
                reject(e)
              }
              reject(e)
            },
            (e) => {
              reject(e)
            },
          )
      })
    },
    optUserNewsletter(context, data) {
      return new Promise((resolve, reject) => {
        //send tracking becon for subscribe
        new newsletterSubscribe().dispatch()
        // We want to store in the user device that he is subscribed
        // to the newsletter, as he could have no account with us, making
        // it impossible to know if he has opted in already or not
        this.commit('app/setRegisteredToNewsletter', true)

        axios
          .post(
            process.env.VUE_APP_VAVE_URL + '/api/users/newsletter',
            {
              email: data.email,
              opt_in: true,
              first_name: data.first_name ?? '',
              last_name: data.last_name ?? '',
              source: data.source ?? ''
            },
            {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            },
          )
          .then(
            (r) => {
              if (r.status == 200) {
                this.dispatch('auth/checkUserIsLoggedIn').then(() => resolve(r), () => resolve(r))
              } else {
                let e = new Error('Could not update user marketing preferences')
                e.response = r
                reject(e)
              }
            },
            (e) => {
              console.log(e)
              reject(e)
            },
          )
      })
    },
    updateUser(context, body) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_VAVE_URL +
            '/api/users/' +
            this.state.auth.user.id,
            body,
            {
              withCredentials: true,
              headers: { 'Content-Type': 'application/json' },
            },
          )
          .then(
            (r) => {
              if (r.status == 200) {
                this.commit('auth/setUser', r.data.user)
                resolve(r)
              }
              let e = new Error('Could not update the user')
              e.response = r
              reject(e)
            },
            (e) => {
              console.log(e)
              reject(e)
            },
          )
      })
    },
    registerGuest(context, user) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_VAVE_URL + '/api/guests', user, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          })
          .then(
            (r) => {
              if (r.status == 201) {
                resolve()
              }
              reject(r)
            },
            (e) => {
              reject(e)
            },
          )
      })
    },
  },
}
