<template>
  <div>
    <ui :label="label" :active="active">
      <p>Please specify price range</p>
      <div class="slider-outer">
        <vue-slider
          v-model="prices"
          :marks="{
            0: '£0',
            5: '£5',
            10: '£10',
            15: '£15',
            20: '£20',
            30: '£30',
            40: '£40',
            50: '£50+',
          }"
          :min="0"
          :max="50"
          :interval="1"
          :enableCross="false"
          :tooltipFormatter="'£{value}'"
          :dot-attrs="{ 'aria-label': 'Select Price Range' }"
        />
      </div>
    </ui>
  </div>
</template>

<script>
import ui from "../drillUI";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: ["value"],
  name: "price",
  data() {
    return {
      prices: this.value,
    };
  },

  methods: {},

  watch: {
    value: {
      deep: true,
      handler() {
        this.prices = this.value;
      },
    },
    prices: {
      deep: true,
      handler() {
        this.$emit("input", this.prices);
      },
    },
  },

  computed: {
    label() {
      return 'Price ' + (this.active? '(£' + this.prices[0] + ' - £'+ (this.prices[1] == 50? '50+':this.prices[1]) + ')':'')
    },
    active() {
      if(this.prices[0] !== 0 || this.prices[1] !== 50) {
        return true;
      }
      return false;
    }
  },

  components: {
    ui,
    VueSlider,
  },
};
</script>

<style lang="scss" scoped>
.slider-outer {
  padding: 30px;
  min-height: 100px;
  background-color: white;
  border-radius: 10px;
}
</style>