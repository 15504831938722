import tracker from "../tracker";

export default class register {

  constructor() {

  }

  dispatch() {
    tracker.publish(this)
  }
}