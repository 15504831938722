<template>
  <div :class="{disabled: categories.length == 0}">
    <ui :label="category.name + ' Category ' + (checked.length? '(' + checked.length + ')':'')" :active="checked.length">
      <ui-options :options="categories" v-model="checked"></ui-options>
    </ui>
  </div>
</template>

<script>
import ui from "../drillUI";
import uiOptions from "../uiOptions";

export default {
  props: ["value","filters"],
  name: "subcategories",
  data() {
    return {
      checked: this.value,
    };
  },

  methods: {
    map() {
      return [...this.$store.state.app.filters.categories].map((c) => {
        return {
          label: c.name,
          value: c.id,
          key: c.id,
          true_depth: c.depth,
          depth: 0,
          parent: c.parent,
        };
      }).filter((e) => {
        return( e.label !== 'All Products' && e.label !== 'Homepage Featured');
      }).filter((e) => {
        return(e.parent == this.filters.categories[0]);
      });
    }
  },
  computed: {
    categories() {
      return this.map();
    },
    category() {
      var cat = this.$store.getters["app/getCategoryById"](
        this.filters.categories[0]
      );
      return cat;
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.checked = this.value;
      },
    },
    checked() {
      this.$emit("input", this.checked);
    }
  },

  components: {
    ui,
    uiOptions,
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>