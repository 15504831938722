import { filter, forEach } from "lodash";

export default {
  
  filter(filters,products) {
    if (filters.categories.length == 0) {
        return products;
      }

      return filter(products, (product) => {
        if (filters.categories.length == 0) {
          return products;
        }
        var found = false;
        forEach(product.categories, (cat) => {
          const i = filters.categories.indexOf(cat.id);
          if (i > -1) {
            found = true;
          }
        });
        return found;
      });
  }
}