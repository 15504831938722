<template>
  <mm-item @active="activate($event)" :class="item.colour" :to="item.to">
    <span v-html="item.label"></span>
    <template v-slot:content v-if="item.cols.length > 0">
      <div class="col-auto" v-for="col, index in item.cols" :key="index">
        <h5 v-html="col.heading"></h5>
        <router-link v-for="link, index in col.links" :key="index" :to="link.to">
          <span v-html="link.anchor"></span>
        </router-link>
      </div>
      <div class="col"></div>
      <div class="col-5" v-if="item.featuredSlugs.length > 0">
        <filtered-products :max-items="item.featuredSlugs.length" :slugs="item.featuredSlugs" />
      </div>
      <div class="col-4" style="padding-top: 16px;" v-if="item.featuredImages && item.featuredImages.length > 0">
        <div class="row">
          <div class="col-4" v-for="featuredImage, imagekey in item.featuredImages" :key="imagekey">
            <router-link :to="featuredImage.to">
              <div class="featured-image" :style="'background-image:url('+featuredImage.src+')'"></div>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </mm-item>
</template>

<script>
import mmItem from "../megaMenuItem";
import filteredProducts from "../../filteredProducts.vue";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  name: "item",
  data() {
    return {};
  },
  components: {
    mmItem,
    filteredProducts,
  },
  methods: {
    activate(event) {
      this.$emit("activated", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-image {
  width: 80%;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>