<template>
  <div :class="{themeDark:dark}">
    <a :href="'tel:' + phone" class="tel" @click="track">{{label}}</a>
  </div>
</template>

<script>
import clicktoCallEvent from '../../events/eventTypes/clickToCall';
export default {
  props: ["phone", "label", "dark"],
  methods: {
    track() {
      new clicktoCallEvent(this.number).dispatch();
    }
  }
};
</script>


<style lang="scss" scoped>
.themeDark {
  a.tel {
    &:hover {
      color: white !important;
    }
  }
}
</style>