import axios from 'axios'
import store from './store'

axios.interceptors.request.use(
  function (config) {
    if (store.state.auth.stickyId) {
      config.headers['x-sticky-id'] = store.state.auth.stickyId
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    if (response.headers['x-sticky-id'] !== undefined) {
      store.commit('auth/setStickyId', response.headers['x-sticky-id'])
    }
    if (response.headers['x-cart-id'] !== undefined) {
      store.dispatch('cart/getServerCart', response.headers['x-cart-id']);
    }


    return response
  },
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.status == 'cart_not_found'
    ) {
      store.dispatch('cart/syncCartWithServer')
    }

    return Promise.reject(error)
  },
)

export default axios
