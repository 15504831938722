<template>
  <div class="notifier">
    <transition-group name="list-complete">
      <div
        class="notification list-complete-item"
        v-for="n in notifications"
        data-cy="notification"
        :key="n.id"
        :class="[n.type, n.pulse ? 'pulse' : '', n.sticky ? 'sticky' : '']"
        @click="remove(n)"
      >
        <icon class="close-icon" type="times" height="20px" width="20px"/>
        <h1>
          <icon :type="n.icon" height="24px" width="24px" />
          &nbsp;{{ n.title }}
        </h1>
        <p v-html="n.message">
        </p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import EventBus from "../bus";
export default {
  data() {
    return {
      EventBus: EventBus,
    };
  },
  mounted() {
    this.EventBus.$on("cart.qty.increased", (evt) => {
      const msg =
        this.getName(evt.item) + " quantity increased to " + evt.item.quantity;
      if (this.$store.state.checkout.globalCurrentStep >= 2) {
        this.$store.commit("checkout/currentStep", 1);
      }

      this.$store.commit("app/addNotification", {
        type: "good",
        title: "Quantity Increased",
        message: msg,
        re: evt.item.sku,
        icon: "circlePlus",
      });
    });
    this.EventBus.$on("cart.qty.decreased", (evt) => {
      const msg =
        this.getName(evt.item) + " quantity decreased to " + evt.item.quantity;
      if (this.$store.state.checkout.globalCurrentStep >= 2) {
        this.$store.commit("checkout/currentStep", 1);
      }

      this.$store.commit("app/addNotification", {
        type: "good",
        title: "Quantity Decreased",
        message: msg,
        re: evt.item.sku,
        icon: "circleMinus",
      });
    });
    this.EventBus.$on("cart.item.added", (evt) => {
      const msg = this.getName(evt.item) + " added to your basket.";
      if (this.$store.state.checkout.globalCurrentStep >= 2) {
        this.$store.commit("checkout/currentStep", 1);
      }

      this.$store.commit("app/addNotification", {
        type: "good",
        title: "Added to Basket",
        message: msg,
        re: evt.item.sku,
      });
    });
  },
  computed: {
    notifications() {
      return this.$store.state.app.notifications;
    },
  },
  methods: {
    getName(item) {
      var name = item.product.name;
      if (item.variation.id) {
        name += " (" + item.variation.name + ")";
      }
      return name;
    },
    remove(n) {
      this.$store.commit("app/removeNotification", n);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";

.notifier {
  position: fixed;
  bottom: 25px;
  @include media-breakpoint-up(sm) {
    right: 15px;
  }
  z-index: 100;
  pointer-events: none;
  display: flex;
  justify-content: center;
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 400px;
  }
  .notification {
    width: 96vw;
    @include media-breakpoint-up(sm) {
      width: 400px;
    }
    padding: 10px;
    border-radius: 2px;
    margin-top: 10px;
    filter: drop-shadow(0 0px 5px rgba(0, 0, 0, 0.2));
    transition: all 150ms ease-in-out;
    display: inline-block;
    pointer-events: all;
    opacity: 0.95;
    h1 {
      font-size: 1.7em;
      // Align the icon with the title
      display:flex;
      align-items:center;
    }
    &.good {
      background: #259e57;
      color: white;
    }
    &.warn {
      background: #feb103;
      color: rgb(37, 37, 37);
    }
    &.bad {
      background: tomato;
      color: white;
    }
    &.pulse {
      animation: tada;
      animation-duration: 1s;
    }
    &.sticky {
      transform: translateX(calc(-50vw + 200px)) translateY(calc(-50vh + 100px));
      box-shadow: 0 0px 0px 200vh rgba(black, 0.3);
    }
    .close-icon{
      position:absolute;
      right: 6px;
      top: 6px;
    }
  }
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: scale(0);
}
.list-complete-leave-active {
  position: absolute;
}
</style>