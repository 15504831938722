/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
var Uri = require('jsuri');

function isOrganic(referrer) {
    if (!referrer) {
        return false;
    }
    var uri = new Uri(referrer);

    var organics = [
        { host: 'yahoo.com', param: 'p', display: 'yahoo' },
        { host: 'about.com', param: 'q', display: 'about' },
        { host: 'aol.com', param: 'q', display: 'aol' },
        { host: 'bing.com', param: 'q', display: 'bing' },
        { host: 'ask.com', param: 'q', display: 'ask' },
        { host: 'globososo.com', param: 'q', display: 'globo' },
        { host: 'go.mail.ru', param: 'q', display: 'go.mail.ru' },
        { host: 'rambler.ru', param: 'query', display: 'rambler' },
        { host: 'tut.by', param: 'query', display: 'tut.by' },
    ];

    var y_host = 'yandex',
        y_param = 'text',
        g_host = 'google';

    var y_host_regex = new RegExp('^(?:.*\\.)?' + (y_host) + '\\..{2,9}$'),
        y_param_regex = new RegExp('.*' + (y_param) + '=.*'),
        g_host_regex = new RegExp('^(?:www\\.)?' + (g_host) + '\\..{2,9}$');

    if (
        uri.query() &&
        uri.host().match(y_host_regex) &&
        uri.query().match(y_param_regex)
    ) {
        // __sbjs_source = y_host;
        return true;
    } else if (uri.host().match(g_host_regex)) {
        // __sbjs_source = g_host;
        return true;
    } else if (uri.query()) {
        for (var i = 0; i < organics.length; i++) {
            if (
                uri

                    .host().match(
                        new RegExp(
                            '^(?:.*\\.)?' + (organics[i].host) + '$',
                            'i'
                        )
                    ) &&
                uri
                    .query().match(
                        new RegExp('.*' + (organics[i].param) + '=.*', 'i')
                    )
            ) {
                // __sbjs_source = organics[i].display || organics[i].host;
                return true;
            }
            if (i + 1 === organics.length) {
                return false;
            }
        }
    } else {
        return false;
    }
}

export default isOrganic
