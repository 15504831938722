import tracker from "../tracker";

export default class openBasket {

  constructor(cartId) {
    this.cartId = cartId;
  }

  dispatch() {
    tracker.publish(this)
  }
}