import { filter, forEach } from 'lodash'

export default {
  filter(filters, products) {
    if (filters.lensCoatings.length == 0) {
      return products
    }

    return filter(products, (product) => {
      var found = false
      forEach(product.lensCoatings, (coating) => {
        const i = filters.lensCoatings.indexOf(coating)
        if (i > -1) {
          found = true
        }
      })
      return found
    })
  },
}
