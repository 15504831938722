<template>
    <div>
        <ui label="Protection Level">
            Protection Level
        </ui>
    </div>
</template>

<script>

import ui from "../drillUI.vue"

export default {
name: 'resp',
components: {
    ui
}
    
}
</script>