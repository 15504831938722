<template>
  <div style="position: relative">
    <!-- buttons -->
    <div>
      <buttonPrimary class="btn-basket d-none d-md-block" @click.prevent="showCart = !showCart">
        Basket
        <span>{{ $store.state.cart.items.length }}</span>
      </buttonPrimary>
      <div class="d-md-none text-white d-md-none btn-basket-mobile" style="font-size: 2em" @click.prevent="showCart = !showCart">
        <icon class="text-yellow" type="cart" width="40px" height="33px" />
        <span>{{ $store.state.cart.items.length }}</span>
      </div>
    </div>
    <!-- buttons -->

    <!-- overlay -->
    <div class="basket-overlay" :class="{ show: showCart }" @click="hidecart"></div>
    <!-- /overlay -->

    <!-- cart -->
    <div class="cart-container">
      <div class="cart-items" :class="{ show: showCart }">
        <div v-if="$store.state.cart.items.length">
          <div class="cart-items-scoller">
            <div v-for="item in $store.state.cart.items" :key="item.sku" class="cart-item container-fluid">
              <div class="row">
                <div class="col mb-2">
                  <router-link
                    class="view-product"
                    @click.native="showCart = false"
                    :to="{
                      name: 'showProduct',
                      params: {
                        product: item.product.slug,
                        productName: item.product.name,
                      },
                    }"
                  >
                    <span class="name">
                      {{
                      item.product.name
                      }}
                    </span>
                  </router-link>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-2">
                  <img :src="image(item)" alt class="img-fluid product-image" />
                </div>
                <div class="col-5">
                  <div class="row" v-if="item.variation">
                    <div class="col">
                      <small>{{ item.variation.name }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <small>SKU: {{ item.sku }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      £{{ bulkDiscountActive && getIsDiscounted(item) ? getBulkUnitPrice(item) : getPrice(item) | money }}
                      <small v-if="bulkDiscountActive && getIsDiscounted(item)" class="bulkpricingactive">Bulk pricing active</small>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <div class="row">
                    <div class="col pr-0 quantity">
                      <button class="btn btn-decrease" @click="decrement(item.sku)" style="margin-left: -4px" v-if="item.quantity > 1">
                        <icon type="minusSquare" width="20px" height="20px" />
                      </button>
                      <button class="btn btn-delete" @click.stop="remove(item.sku)" style="margin-left: -4px" v-else>
                        <icon type="trash" width="18px" height="18px" />
                      </button>
                      <span>{{ item.quantity }}</span>
                      <button class="btn btn-increase" @click="increment(item.sku)" style="margin-right: -4px">
                        <icon type="plusSquare" width="20px" height="20px" />
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-center mt-2">
                      Subtotal
                      <strong>£{{ getIsDiscounted(item) ? getBulkItemSubtotal(item) : getItemSubtotal(item) | money }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="banner" :class="{ show: showCart }">
            <template v-if="subtotal < 50">
              Only&nbsp;
              <strong>£{{ (50 - subtotal) | money }}</strong>&nbsp;away from free shipping!
            </template>
            <template v-else>
              <icon type="gem" width="20px" height="20px" />&nbsp;You unlocked
              <strong>&nbsp;Free Shipping!</strong>
            </template>
          </div>
          <div class="summary" :class="{ show: showCart }">
            <div class="row align-items-center">
              <div class="col">
                <span class="summary-label">Basket Subtotal:</span>
              </div>
              <div class="col-auto">
                <div>
                  <span class="summary-value">£{{ subtotal | money }}</span>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <small style="font-size: 0.7em">Shipping &amp; coupons will be applied at checkout</small>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col text-center">
                <div class="d-grid gap-2">
                  <router-link :to="{ name: 'basket' }" @click.native="showCart = false" class="btn btn-block btn-muted text-dark">View Basket</router-link>
                  <router-link :to="{ name: 'basket' }" @click.native="showCart = false" class="btn btn-block btn-basket" data-cy="checkout-button">Go to Checkout</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty-basket text-center text-muted mt-2">
          <h3>Your basket is empty</h3>
        </div>
      </div>
    </div>
    <!-- /cart -->
  </div>
</template>


<script>
import buttonPrimary from "./ui/buttons/buttonPrimary.vue";
import EventBus from "../bus";
import basketFunctions from "../mixins/basket";

export default {
  mixins: [basketFunctions],
  data() {
    return {
      EventBus,
      showCart: false,
    };
  },
  computed: {
    items() {
      return this.$store.state.cart.items;
    },
  },
  watch: {
    showCart(value) {
      if (value == true) {
        this.$store.dispatch("cart/syncItemsProduct");
      }
    },
    items: {
      deep: true,
      handler() {
        this.$store.commit("cart/totalsPending", true);
        if (this.updateTotalsTimer) {
          clearTimeout(this.updateTotalsTimer);
        }
        this.updateTotalsTimer = setTimeout(() => {
          this.reactToChanges();
        }, 500);
      },
    },
  },
  methods: {
    reactToChanges() {
      let email = "";
      if (this.$store.state.auth.user.id) {
        email = this.$store.state.auth.user.email;
      }
      if (this.$store.state.cart.coupon.code !== null) {
        // setCoupon will call getServerTotals after setting the coupon, or failing to do it
        this.$store.dispatch("cart/setCoupon", {
          coupon: this.$store.state.cart.coupon.code,
        });
      } else {
        this.$store.dispatch("cart/getServerTotals", email)
      }
    },
    hidecart() {
      this.showCart = false;
    },
  },
  components: {
    buttonPrimary,
  },
};
</script>


<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";

.btn-basket {
  position: relative;
  z-index: 900;
  span {
    background-color: #f3453f;
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
  }
}

.btn-basket-mobile {
  position: relative;
  span {
    background-color: white;
    display: block;
    position: absolute;
    top: 10px;
    left: -10px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: bold;
    border-radius: 50%;
    font-size: 0.5em;
  }
  .text-yellow {
    color: #feb103;
  }
}

.basket-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 50;
  opacity: 0;
  pointer-events: none;
  transform-origin: top right;
  transition: all 300ms ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: unset;
  }
}

.cart-container {
  position: absolute;
  right: -0px;
  a {
    text-decoration: none !important;
  }

  .btn-basket {
    background: #feb103;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important;
    z-index: 502;
    position: relative;
    font-weight: 700;
  }
  .btn-muted {
    background: #e4e4e4;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important;
  }
  .cart-items {
    position: fixed;
    top: 90px;
    width: 100vw;

    right: 0px;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: -46px;
      right: 0px;
      width: 500px;
      left: unset;
    }

    opacity: 0;
    pointer-events: none;
    transform: translateY(-40px);
    transform-origin: top right;
    transition: opacity 300ms ease-in-out,
      transform 700ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
      border-top 700ms ease-in-out;
    perspective: 800px;
    img {
      opacity: 0;
      transform: scale(0);
      transition: all 700ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
    }
    &.show {
      opacity: 1;
      pointer-events: unset;
      transform: scale(1);
      img {
        opacity: 1;
        transform: scale(1);
      }
    }
    // background: rgba($color: white, $alpha: 1);
    border-radius: 5px;
    color: black;

    z-index: 90;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.2));

    .cart-items-scoller {
      max-height: 50vh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .cart-item {
      background: rgb(245, 245, 245);
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      padding-top: 15px;
      padding-bottom: 15px;
      z-index: 90;
      position: relative;
      text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.15);
      .product-image {
        border-radius: 50%;
        padding: 5px;
        background: white;
        filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.2));
      }
      .name {
        font-weight: bold;
        font-size: 1.2em;
        color: #feb103;
        text-shadow: 1px 1px 1px rgba($color: #9e7005, $alpha: 0.15);
      }
      small {
        color: rgb(88, 88, 88);
      }
      .quantity {
        // background: rgba(0, 0, 0, 0.9);
        color: white;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-decrease {
          background: orange;
          border-color: orange;
          outline: none !important;
          box-shadow: none !important;
          color: white;
          font-weight: bold;
        }
        .btn-increase {
          background: rgb(130, 196, 69);
          border-color: rgb(130, 196, 69);
          outline: none !important;
          box-shadow: none !important;
          color: white;
          font-weight: bold;
        }
        .btn-delete {
          background: rgb(133, 7, 17);
          border-color: rgb(133, 7, 17);
          outline: none !important;
          box-shadow: none !important;
          color: white;
          font-weight: bold;
        }
        span {
          width: 45px;
          display: inline-block;
          text-align: center;
          overflow: hidden;
          color: black;
          font-weight: bold;
        }
      }
    }
  }
}
.summary {
  padding: 20px;
  padding-top: 30px;
  background: #363738;
  background-image: radial-gradient(
    rgba(219, 219, 219, 0.08) 1px,
    transparent 0
  );
  background-size: 10px 10px;
  background-position: -9px -9px;
  position: relative;
  z-index: 88;
  color: white;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;
  transform-origin: top center;
  transform: rotate3d(300, 0, 0, -96deg);
  border-radius: 0 0 10px 10px;
  text-shadow: 1px 1px 1px rgba($color: #000000, $alpha: 0.15);
  &.show {
    transform: rotate3d(0, 0, 0, 0deg);
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
  }
  .summary-value {
    font-size: 1.2em;
    font-weight: bold;
  }
}
.banner {
  position: relative;
  z-index: 89;
  background: #feb103;
  background: radial-gradient(
    circle,
    rgb(255, 203, 89) 0%,
    rgba(254, 177, 3, 1) 100%
  );
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  box-shadow: 0 6px 6px 5px rgba($color: #473302, $alpha: 0.1);
  border-top: 1px solid #ffca4f;
  @include media-breakpoint-up(md) {
    margin-left: -15px;
    margin-right: -15px;
    &:before {
      content: "";
      position: absolute;
      top: -15px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 15px 15px;
      border-color: transparent transparent #bb8302 transparent;
    }
    &:after {
      content: "";
      position: absolute;
      top: -15px;
      right: 0px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 0 15px;
      border-color: transparent transparent transparent #bb8302;
    }
  }
}

.empty-basket {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: calc(100% + 50px);
  right: 0px;
}
.bulkpricingactive {
  font-size: 0.8em;
}
</style>