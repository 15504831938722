/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

import Vue from 'vue';

import axios from 'axios';
import marketingOptIn from "./eventTypes/marketingOptIn"
import marketingOptOut from "./eventTypes/marketingOptOut"

export default new Vue({
    data() {
        return {
        }
    },
    computed: {
        baseurl() {
            return process.env.VUE_APP_VAVE_URL;
        }
    },
    methods: {
        init(tracker) {
            tracker.subscribe(this.event)
        },
        storeEvent(event) {
            this.storedEvents.push(event);
        },
        handleStoredEvents() {
            this.storedEvents.forEach(event => {
                console.log('refiring', event)
                this.event(event);
            })
        },
        event(event) {
            if (event instanceof marketingOptIn) {
                this.optInForMarketing(true, event.email, event.phone, event.firstName, event.lastName)
            }
            if (event instanceof marketingOptOut) {
                this.optInForMarketing(false, event.email)
            }
        },
        optInForMarketing(optIn, email, phone, firstName, lastName) {
            axios({
                method: 'post',
                url: this.baseurl + '/api/users/newsletter',
                data: {
                    email: email,
                    opt_in: optIn,
                    first_name: firstName,
                    last_name: lastName,
                    phone: phone,
                    source: "checkout",
                },
                withCredentials: true
            })

        },
    }
});
