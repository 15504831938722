import $axios from '../http'
import 'core-js/es/array';

export default {
    namespaced: true,
    state: {
        products: [],
        filters: {},
        utm: {},
        filtersReady: false,
        productsLoaded: false,
        notifications: [],
        blogArticles: [],
        blogArticlesLoaded: true,
        canShow: true,
        showNewsletterPopup: false,
        newsletterPopupDismissedAt: null,
        registeredToNewsletter: false,
        showBulkPricing: window.innerWidth > 768 ? true : false, // show bulk by default on desktop
    },

    mutations: {
        clearUtm(state) {
            state.utm = {};
        },
        setUtm(state, payload) {
            state.utm[payload.key] = payload.value;
        },
        canShow(state, value) {
            state.canShow = value;
        },
        productsLoaded(state, value) {
            state.productsLoaded = value
        },
        filtersReady(state, value) {
            state.filtersReady = value
        },
        setProducts(state, products) {
            state.products = products
        },
        setShowBulkPricing(state, value) {
            state.showBulkPricing = value
        },
        addVariations(state, payload) {
            state.products.forEach(product => {
                if (product.id == payload.product) {
                    product.variations = payload.variations

                }
            })
        },
        blogArticlesLoaded(state, value) {
            state.blogArticlesLoaded = value
        },
        setBlogArticles(state, articles) {
            state.blogArticles = articles
        },
        setFilters(state, filters) {

            state.filters = filters
            state.filtersReady = true
        },
        removeNotification(state, n) {
            const index = state.notifications.indexOf(n)
            if (index > -1) {
                state.notifications.splice(index, 1)
            }
        },
        unPulse(state, n) {
            const index = state.notifications.indexOf(n)
            if (index > -1) {
                state.notifications[index].pulse = false
                state.notifications[index].message += " "
            }

        },
        dismissNewsletterPopup(state) {
            state.newsletterPopupDismissedAt = new Date;
            state.showNewsletterPopup = false;
        },
        showNewsletterPopup(state, status) {
            state.showNewsletterPopup = status;
        },
        setRegisteredToNewsletter(state, status) {
            state.registeredToNewsletter = status;
        },
        addNotification(state, n) {
            var found
            state.notifications.forEach((notification) => {
                if (notification.re == n.re) {
                    found = notification
                }
            })
            if (found) {

                n.id = found.id
                var icon
                if (!n.icon) {
                    icon = 'circle-info'
                } else {
                    icon = n.icon
                }
                clearTimeout(found.timeout)
                if (found.sticky !== true) {
                    found.timeout = setTimeout(() => {
                        this.commit('app/removeNotification', found)
                    }, 5000);
                }
                found.type = n.type
                found.title = n.title
                found.message = n.message
                found.re = n.re
                found.icon = icon
                found.pulse = true

                setTimeout(() => {
                    this.commit('app/unPulse', found)
                }, 1000);


            } else {
                if (n.sticky !== true) {
                    n.timeout = setTimeout(() => {
                        this.commit('app/removeNotification', n)
                    }, 5000);
                }
                if (n.icon == undefined) {
                    n.icon = 'circle-info'
                }
                state.notifications.push(n)
                n.id = + new Date()
            }
        }
    },

    getters: {
        getBrandByName: (state) => (name) => {
            var found
            if (!state.filters || !state.filters.brands) {
                return found;
            }
            state.filters.brands.forEach(brand => {
                if (brand.toUpperCase() == name.toUpperCase()) {
                    found = brand
                }
            });
            return found
        },

        getProductByName: (state) => (name) => {
            var found
            if (!state.products) {
                return found;
            }
            state.products.forEach(product => {
                if (product.name == name) {
                    found = product
                }
            })
            return found
        },

        getProductBySlug: (state) => (slug) => {
            var found
            if (!state.products) {
                return found;
            }
            state.products.forEach(product => {
                if (product.slug == slug) {
                    found = product
                }
            })
            return found
        },

        getProductByRemoteId: (state) => (remoteId) => {
            var found
            if (!state.products) {
                return found;
            }
            state.products.forEach(product => {
                if (product.remote_id == remoteId) {
                    found = product
                }
            })
            return found
        },

        getProductById: (state) => (id) => {
            var found
            if (!state.products) {
                return found;
            }
            state.products.forEach(product => {
                if (product.id == id) {
                    found = product
                }
            })
            return found
        },

        getCategoryById: (state) => (id) => {
            var found
            if (!state.filters || !state.filters.categories) {
                return found;
            }
            state.filters.categories.forEach(category => {
                if (id == category.id) {
                    found = category
                }
            });
            return found

        },

        getCategoryByName: (state) => (name) => {
            var found
            if (!state.filters || !state.filters.categories) {
                return found;
            }
            state.filters.categories.forEach(category => {
                if (name.toUpperCase() == category.name.toUpperCase()) {
                    found = category
                }
            });
            return found

        },

        getCategoryBySlug: (state) => (slug) => {
            var found
            if (!state.filters || !state.filters.categories) {
                return found;
            }
            state.filters.categories.forEach(category => {
                if (slug.toUpperCase() == category.slug.toUpperCase()) {
                    found = category
                }
            });
            return found
        },
        getBlogArticleBySlug: (state) => (slug) => {
            var found;
            state.blogArticles.forEach(article => {
                if (slug == article.slug) {
                    found = article;
                    return;
                }
            });
            return found;
        },
    },

    actions: {
        setShowBulkPricing(store, payload) {
            store.commit('setShowBulkPricing', payload)
        },
        clearUtmValues(store) {
            store.commit('clearUtm');
        },
        setUtmValues(store, payload) {
            store.commit('clearUtm');
            // Loop through the utm params and set them in the store
            for (var key in payload) {
                if (Object.prototype.hasOwnProperty.call(payload, key)) {
                    store.commit('setUtm', {
                        key: key,
                        value: payload[key]
                    })
                }
            }
        },
        async syncBlogArticles(store) {
            // TODO Note: this could load some non necessary stuff in memory/local storage
            const result = $axios.get(process.env.VUE_APP_VAVE_URL + "/api/posts?per_page=200");
            result.then((response) => {
                store.commit('setBlogArticles', response.data.data)
                store.commit('blogArticlesLoaded', true)
            });
        },
        async syncProducts(store) {
            const result = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/products?per_page=2000')
            result.then(response => {
                store.commit('setProducts', response.data.data)
                store.commit('productsLoaded', true)
            })
        },
        async syncAspects(store) {

            // const brandsResult = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/search/config/brands')
            // const applicationsResult = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/search/config/applications')
            // const coatingsResult = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/search/config/coatings')
            // const linersResult = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/search/config/liners')
            // const categoryResult = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/categories?flat=1')

            const result = $axios.get(process.env.VUE_APP_VAVE_URL + '/api/search/config/aspects')

            store.commit('setFilters', {
                brands: (await result).data.brands,
                applications: (await result).data.applications,
                coatings: (await result).data.coatings,
                liners: (await result).data.liners,
                categories: (await result).data.categories,
                industries: (await result).data.industries,
                lensCoatings: (await result).data.lensCoatings,
                sizes   : (await result).data.sizes,
                sizeMap : (await result).data.sizeMap,
                attenuationlevels: (await result).data.attenuationlevels,
                cutLevels: (await result).data.cutlevels,
            })
        }
    },
}