import tracker from "../tracker";

export default class selectVariation {

  constructor(variation, cartId) {
    this.variation = variation;
    this.cartId = cartId;
  }

  dispatch() {
    tracker.publish(this)
  }
}