/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
// sends tracking events to Vave
// SUPPORTED EVENTS
// ------------------
// viewProduct
// selectVariation
// viewProductList
// addToCart
// register
// checkout
// beginCheckout
// stepCheckout

import Vue from 'vue';

import heartbeat from "./eventTypes/heartbeat";
import selectVariationEvent from "./eventTypes/selectVariation";
import viewProductEvent from "./eventTypes/viewProduct";
import viewProductList from "./eventTypes/viewProductList";
import search from "./eventTypes/search";
import addToCart from "./eventTypes/addToCart";
import removeFromCart from "./eventTypes/removeFromCart";
import register from "./eventTypes/register";
import checkout from "./eventTypes/checkout";
import beginCheckout from "./eventTypes/beginCheckout";
import stepCheckout from "./eventTypes/stepCheckout";
import failedCheckout from "./eventTypes/failedCheckout";
import inputUpdated from "./eventTypes/inputUpdated";
import marketingOptIn from "./eventTypes/marketingOptIn";
import marketingOptOut from "./eventTypes/marketingOptOut";
import couponAdded from "./eventTypes/couponAdded";
import openBasket from "./eventTypes/openBasket";
import openBlogArticle from "./eventTypes/openBlogArticle";
import paymentFailed from "./eventTypes/paymentFailed";
import paymentSucceeded from "./eventTypes/paymentSucceeded";
import couponRemoved from "./eventTypes/couponRemoved";
import logout from "./eventTypes/logout";
import visitPage from "./eventTypes/visitPage";
import saveNewAddress from "./eventTypes/saveNewAddress";
import deleteAddress from "./eventTypes/deleteAddress";
import changePassword from "./eventTypes/changePassword"
import paymentCardSelected from "./eventTypes/paymentCardSelected"
import orderAgain from "./eventTypes/orderAgain"
import filtersChanged from "./eventTypes/filtersChanged";
import orderProducts from "./eventTypes/orderProducts";
import axios from 'axios';

export default new Vue({
  data() {
    return {
    }
  },
  computed: {
    baseurl() {
      return process.env.VUE_APP_VAVE_URL;
    },
  },
  methods: {
    init(tracker) {
      tracker.subscribe(this.event)
    },
    storeEvent(event) {
      this.storedEvents.push(event);
    },
    handleStoredEvents() {
      this.storedEvents.forEach(event => {
        this.event(event);
      })
    },
    event(event) {
      if (event instanceof viewProductEvent) {
        this.viewProduct(event)
      }
      if (event instanceof selectVariationEvent) {
        this.selectVariation(event)
      }
      if (event instanceof viewProductList) {
        this.viewItemList(event)
      }
      if (event instanceof addToCart) {
        this.addToCart(event)
      }
      if (event instanceof removeFromCart) {
        this.removeFromCart(event)
      }
      if (event instanceof filtersChanged) {
        this.filtersChanged(event)
      }
      if (event instanceof orderProducts) {
        this.orderProducts(event)
      }
      if (event instanceof search) {
        this.search(event)
      }
      if (event instanceof register) {
        this.register(event)
      }
      if (event instanceof beginCheckout) {
        event.checkout_step = 1;
        this.checkoutStep(event)
      }
      if (event instanceof stepCheckout) {
        this.checkoutStep(event)
      }
      if (event instanceof inputUpdated) {
        this.inputUpdated(event)
      }
      if (event instanceof openBasket) {
        this.openBasket(event)
      }
      if (event instanceof openBlogArticle) {
        this.openBlogArticle(event)
      }
      if (event instanceof marketingOptOut) {
        // this.marketingOptOut(event)
      }
      if (event instanceof marketingOptIn) {
        // this.marketingOptIn(event)
      }
      if (event instanceof couponAdded) {
        this.couponAdded(event)
      }
      if (event instanceof couponRemoved) {
        this.couponRemoved(event)
      }
      if (event instanceof checkout) {
        this.checkout(event)
      }
      if (event instanceof failedCheckout) {
        this.failedCheckout(event)
      }
      if (event instanceof paymentSucceeded) {
        this.paymentSucceeded(event)
      }
      if (event instanceof paymentFailed) {
        this.paymentFailed(event)
      }
      if (event instanceof logout) {
        this.logout(event)
      }
      if (event instanceof saveNewAddress) {
        this.saveNewAddress(event)
      }
      if (event instanceof deleteAddress) {
        this.deleteAddress(event)
      }
      if (event instanceof changePassword) {
        this.changePassword(event)
      }
      if (event instanceof orderAgain) {
        this.orderAgain(event)
      }
      if (event instanceof visitPage) {
        this.visitPage(event)
      }
      if (event instanceof heartbeat) {
        this.heartbeat(event)
      }
      if (event instanceof paymentCardSelected) {
        this.paymentCardSelected(event)
      }
    },
    paymentSucceeded(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'checkout_payment_succeeded',
        order_id: event.orderId,
        data: event,
      }
      this.sendRequest(payload);
    },
    paymentFailed(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'checkout_payment_failed',
        order_id: event.orderId,
        data: event,
      }
      this.sendRequest(payload);
    },
    inputUpdated(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'input_updated',
        data: event,
      }
      this.sendRequest(payload);
    },
    checkoutStep(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'checkout_step',
        cart_id: event.cartId,
        order_id: event.orderId,
        data: event,
      }
      this.sendRequest(payload);
    },
    heartbeat(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'heartbeat',
        data: {},
      }
      this.sendRequest(payload);
    },
    checkout(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'checkout_completed',
        cart_id: event.cartId,
        order_id: event.orderId,
        sticky_id: event.stickyId,
        data: event,
      }
      this.sendRequest(payload);
    },
    failedCheckout(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'checkout_failed',
        order_id: event.orderId,
        data: event,
      }
      this.sendRequest(payload);
    },
    openBasket(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'open_basket',
        cart_id: event.cartId,
        data: event,
      }
      this.sendRequest(payload);
    },
    openBlogArticle(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'open_blog_article',
        data: {
          article: event.articleTitle,
        },
      }
      this.sendRequest(payload);
    },
    visitPage(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'visit_page',
        data: {
          page: event.page,
          meta: event.meta,
        },
      }
      this.sendRequest(payload);
    },
    logout(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'logout',
        data: event,
      }
      this.sendRequest(payload);
    },
    saveNewAddress(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'save_new_address',
        data: { address: event.address },
      }
      this.sendRequest(payload);
    },
    deleteAddress(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'delete_address',
        data: {
          address_id: event.addressId
        },
      }
      this.sendRequest(payload);
    },
    changePassword(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'change_password',
        data: event,
      }
      this.sendRequest(payload);
    },
    orderAgain(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'order_again',
        order_id: event.orderId,
      }
      this.sendRequest(payload);
    },
    marketingOptIn(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'marketing_opt_in',
        data: event,
      }
      // this.sendRequest(payload);
    },
    marketingOptOut(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'marketing_opt_out',
        data: event,
      }
      // this.sendRequest(payload);
    },
    filtersChanged(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'filters_changed',
        cart_id: event.cartId,
        data: event,
      }
      this.sendRequest(payload);
    },
    orderProducts(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'order_products',
        data: { order_by: event.orderBy },
      }
      this.sendRequest(payload);
    },
    register() {

    },
    couponAdded(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'coupon_added',
        cart_id: event.cartId,
        data:
        {
          coupon: event.coupon,
        }
      }
      this.sendRequest(payload);
    },
    couponRemoved(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'coupon_removed',
        cart_id: event.cartId,
        data: {}
      }
      this.sendRequest(payload);
    },
    search(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'search',
        data:
        {
          query: event.query,
          results_count: event.results,
        }
      }
      this.sendRequest(payload);
    },
    addToCart(event) {
      var item = {
        id: event.product.sku,
        name: event.product.name,
        brand: event.product.brand,
        quantity: event.quantity,
        price: event.product.price
      }
      if (event.variation.id) {
        item.price = event.variation.price;
        item.id = event.variation.sku;
        item.name = item.name + ' ' + event.variation.name;
      }
      const payload = {
        ...this.generateBasePayload(event),
        action: 'add_to_cart',
        cart_id: event.cartId,
        data:
        {
          origin: event.origin,
          items: [
            item
          ]
        }
      }
      this.sendRequest(payload);
    },
    removeFromCart(event) {
      var item = {
        id: event.item.product.sku,
        name: event.item.product.name,
        brand: event.item.product.brand,
        price: event.item.product.price
      }
      if (event.item.variation.id) {
        item.price = event.item.variation.price;
        item.id = event.item.variation.sku;
        item.name = item.name + ' ' + event.item.variation.name;
      }
      const payload = {
        ...this.generateBasePayload(event),
        action: 'remove_from_cart',
        cart_id: event.cartId,
        data:
        {
          items: [
            item
          ]
        }
      }
      this.sendRequest(payload);
    },
    viewItemList(products) {
      const chunks = chunkArray(products, 50);
      chunks.forEach(chunk => {
        var payload = {
          items: chunk.map(product => {
            return {
              id: product.id,
              name: product.name,
              brand: product.brand
            }
          })
        }

        //

      })
    },
    viewProduct(event) {
      let product = event.product
      const payload = {
        ...this.generateBasePayload(event),
        action: 'view_product',
        cart_id: event.cartId,
        data:
        {
          product: {
            id: product.id,
            name: product.name,
            brand: product.brand
          }
        }
      }
      this.sendRequest(payload);
    },
    selectVariation(event) {
      let variation = event.variation
      const payload = {
        ...this.generateBasePayload(event),
        action: 'selected_variation',
        cart_id: event.cartId,
        data:
        {
          id: variation.sku,
          name: variation.name,
          brand: variation.brand,
          price: variation.price,
          variant: variation.name
        }
      }
      this.sendRequest(payload);
    },
    paymentCardSelected(event) {
      const payload = {
        ...this.generateBasePayload(event),
        action: 'stripe_card_selected',
        order_id: event.orderId,
        card: event.card,
        data: event,
      }
      this.sendRequest(payload);
    },
    generateBasePayload(event) {
      return {
        referer: document.referrer,
        location: window.location.href,
        screenColours: window.screen.colorDepth + '-bit',
        screeResolution: window.screen.width + ' x ' + window.screen.height,
        viewportSize: window.innerWidth + ' x ' + window.innerHeight,
        pageTitle: document.title,
        timestamp: event.timestamp ? Math.floor(event.timestamp / 1000) : null, // timestamp is in milliseconds
        source: 'pwa',
      }
    },
    sendRequest(payload) {
      axios({
        method: 'post',
        url: this.baseurl + '/api/events',
        data: payload,
        withCredentials: true
      })
    },
  }
});

function chunkArray(array, size) {
  let result = []
  for (let i = 0; i < array.length; i += size) {
    let chunk = array.slice(i, i + size)
    result.push(chunk)
  }
  return result
}