import { sortBy, reverse } from "lodash";

const orderProducts = (products, order = "recommended", strategy = null) => {
    if (order == "pricel2h") {
        return sortBy(products, ["product", "price"]);
    }
    if (order == "priceh2l") {
        return reverse(sortBy(products, ["product", "price"]));
    }
    if (order == "popular") {
        return sortBy(products, ["product", "rank_sales"]);
    }
    if (order == "recommended") {
        if (strategy == null) {
            return sortBy(products, (product) => {
                if (product.ordering_strategies && product.ordering_strategies["_default"]) {
                    return product.ordering_strategies["_default"];
                } else {
                    return null;
                }
            });
        }
        return sortBy(products, (product) => {
            if (strategy && product.ordering_strategies && product.ordering_strategies[strategy]) {
                return product.ordering_strategies[strategy];
            } else {
                return null;
            }
        });
    }
    return products;
}

export default orderProducts;