import tracker from "../tracker";

export default class filtersChanged {

  constructor(filters, cartId, timestamp) {
    this.cartId = cartId;
    this.filters = filters;
    this.timestamp = timestamp;
  }

  dispatch() {
    tracker.publish(this)
  }
}