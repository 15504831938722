<template>
  <div>
    <!-- <pre>{{options}}</pre> -->
    <div
      class="option"
      v-for="option in mapped"
      :key="option.key"
      @click="check(option)"
      :class="{ checked: option.checked }"
      :style="'margin-left:' + Math.round((option.depth * 20)) + 'px;'"
    >
      <div class="row">
        <div class="col-auto pr-o">
          <icon type="squareCheck" width="16px" height="16px"  v-if="option.checked"/>
          <icon type="square" width="16px" height="16px" v-else/>
        </div>
        <div class="col pl-0" v-html="option.label">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["options", "value"],
  data() {
    return {
      blockupdates: false,
      mapped: this.map(),
    };
  },
  mounted() {},
  watch: {
    options() {
      this.mapped = this.map();
    },
    value: {
      deep: true,
      handler() {
        if (!this.blockupdates) {
          this.mapped = this.map();
        }
      },
    },
    mapped: {
      deep: true,
      handler() {
        const elements = this.mapped
          .map((e) => {
            if (e.checked) {
              return e.key;
            }
          })
          //filter nulls
          .filter((e) => {
            if (e !== undefined) {
              return true;
            }
          });
        this.blockEmitUnblock(elements);
      },
    },
  },
  methods: {
    blockEmitUnblock(payload) {
      this.blockupdates = true;
      this.$emit("input", payload);
      this.$nextTick(() => {
        this.blockupdates = false;
      });
    },
    map() {
      return this.options.map((o) => {
        let checked = false;
        this.value.forEach((v) => {
          if (o.key == v) {
            checked = true;
          }
        });

        return {
          checked: checked,
          ...o,
        };
      });
    },
    check(option) {
      option.checked = !option.checked;
    },
  },
};
</script>

<style lang="scss">
.option {
  background-color: white;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  &.checked {
    font-weight: bold;
    background-color: #feb103;
  }
  &:hover {
    background-color: rgb(231, 231, 231);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
</style>