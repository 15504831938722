import { filter } from 'lodash'

export default {
  filter(filters, products) {
    if (filters.cutlevels.length == 0) {
      return products
    }

    return filter(products, (product) => {
      const i = filters.cutlevels.indexOf(product.cutLevel)
      if (i > -1) {
        return true
      } else {
        return false
      }
    })
  },
}
