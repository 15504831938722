import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import axios from './http'

// eslint-disable-next-line no-unused-vars
import { filter, reverse, sortBy, forEach } from 'lodash'

import { categoryFilter, lensCoatingFilter, subcategoryFilter, linerFilter, sizeFilter, attenuationFilter, cutlevelFilter } from './productFilters'

import store from './store'

export default new Vue({
  data() {
    return {
      ordering: 'recommended',
      Initialfilters: {
        brands: [],
        coatings: [],
        liners: [],
        price: [0, 50],
        categories: [],
        subcategories: [],
        industries: [],
        applications: [],
        lensCoatings: [],
        sizes: [],
        attenuationlevels: [],
        cutlevels: [],
      },
      filters: {},
      dirty: false,
      ready: false,
    }
  },

  created() {
    this.filters = { ...this.Initialfilters }
    this.$nextTick(() => {
      this.dirty = false
      this.ready = true
    })
  },

  computed: {
    results() {
      if (!this.ready) {
        return []
      }

      var products = store.state.app.products
      products = this.filterByBrands(products)
      products = this.filterByCoatings(products)
      products = this.filterByPrice(products)
      products = categoryFilter.filter(this.filters,products)
      products = subcategoryFilter.filter(this.filters,products)
      products = linerFilter.filter(this.filters,products)
      products = sizeFilter.filter(this.filters,products,store.state.app.filters.sizeMap)
      products = attenuationFilter.filter(this.filters,products)
      products = cutlevelFilter.filter(this.filters,products)
      products = this.filterbyIndustries(products)
      products = this.filterbyApplications(products)
      products = lensCoatingFilter.filter(this.filters,products)

      return products
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (Object.compare(this.Initialfilters, this.filters)) {
          this.filtersDirty = false
        } else {
          this.filtersDirty = true
        }
      },
    },
  },

  methods: {
    products(filters) {
      if (!this.ready) {
        return []
      }

      var products = store.state.app.products
      products = this.filterByBrandsSync(products, filters)
      products = this.filterByCoatingsSync(products, filters)
      products = this.filterByPriceSync(products, filters)
      products = this.filterbyCategoriesSync(products, filters)
      products = this.filterbyIdsSync(products, filters)
      products = this.filterBySlugSync(products, filters)
      products = this.filterbyIndustriesSync(products, filters)

      return products
    },

    filterbyIdsSync(products, filters) {
      if (filters.ids.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = filters.ids.indexOf(product.id)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },

    filterBySlugSync(products, filters) {
      if (filters.slugs.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = filters.slugs.indexOf(product.slug)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },

    filterByBrandsSync(products, filters) {
      if (filters.brands.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = filters.brands.indexOf(product.brand)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },

    filterbyCategoriesSync(products, filters) {
      if (filters.categories.length == 0) {
        return products
      }

      return filter(products, (product) => {
        if (filters.categories.length == 0) {
          return products
        }
        var found = false
        forEach(product.categories, (cat) => {
          const i = filters.categories.indexOf(cat.id)
          if (i > -1) {
            found = true
          }
        })
        return found
      })
    },

    filterByPriceSync(products, filters) {
      return filter(products, (product) => {
        if (
          product.price >= Math.min(...filters.price) &&
          product.price <= Math.max(...filters.price)
        ) {
          return true
        }

        if (
          product.price >= Math.min(...filters.price) &&
          Math.max(...filters.price) == 50
        ) {
          return true
        }

        return false
      })
    },

    filterByCoatingsSync(products, filters) {
      if (filters.coatings.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = filters.coatings.indexOf(product.coating)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },

    filterbyIndustriesSync(products) {
      if (this.filters.industries.length == 0) {
        return products
      }

      return filter(products, (product) => {
        if (this.filters.industries.length == 0) {
          return products
        }
        var found = false
        forEach(product.industries, (industry) => {
          const i = this.filters.industries.indexOf(industry)
          if (i > -1) {
            found = true
          }
        })
        return found
      })
    },

    clear() {
      this.filters = { ...this.Initialfilters }
    },

    filterByBrands(products) {
      if (this.filters.brands.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = this.filters.brands.indexOf(product.brand)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },


    filterByPrice(products) {
      return filter(products, (product) => {
        if (
          product.price >= Math.min(...this.filters.price) &&
          product.price <= Math.max(...this.filters.price)
        ) {
          return true
        }

        if (
          product.price >= Math.min(...this.filters.price) &&
          Math.max(...this.filters.price) == 50
        ) {
          return true
        }

        return false
      })
    },

    filterByCoatings(products) {
      if (this.filters.coatings.length == 0) {
        return products
      }

      return filter(products, (product) => {
        const i = this.filters.coatings.indexOf(product.coating)
        if (i > -1) {
          return true
        } else {
          return false
        }
      })
    },

    filterbyIndustries(products) {
      if (this.filters.industries.length == 0) {
        return products
      }

      return filter(products, (product) => {
        if (this.filters.industries.length == 0) {
          return products
        }
        var found = false
        forEach(product.industries, (industry) => {
          const i = this.filters.industries.indexOf(industry)
          if (i > -1) {
            found = true
          }
        })
        return found
      })
    },

    filterbyApplications(products) {
      if (this.filters.applications.length == 0) {
        return products
      }

      return filter(products, (product) => {
        if (this.filters.applications.length == 0) {
          return products
        }
        var found = false
        forEach(product.applications, (application) => {
          const i = this.filters.applications.indexOf(application)
          if (i > -1) {
            found = true
          }
        })
        return found
      })
    },
  },
})

Object.compare = function (obj1, obj2) {
  //Loop through properties in object 1
  for (var p in obj1) {
    //Check property exists on both objects
    // eslint-disable-next-line no-prototype-builtins
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false

    switch (typeof obj1[p]) {
      //Deep compare objects
      case 'object':
        if (!Object.compare(obj1[p], obj2[p])) return false
        break
      //Compare function code
      case 'function':
        if (
          typeof obj2[p] == 'undefined' ||
          (p != 'compare' && obj1[p].toString() != obj2[p].toString())
        )
          return false
        break
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false
    }
  }

  //Check object 2 for any extra properties
  // eslint-disable-next-line no-redeclare
  for (var p in obj2) {
    if (typeof obj1[p] == 'undefined') return false
  }
  return true
}
