import Vue from 'vue'

import checkout from './eventTypes/checkout'

export default new Vue({
  methods: {
    init(tracker) {
      tracker.subscribe(this.event)
    },
    event(event) {
      if (event instanceof checkout) {
        this.checkout(event)
      }
    },
    checkout(event) {
      window.uetq = window.uetq || [];  
      window.uetq.push('event', '', {'revenue_value': event.total, 'currency': 'GBP'});
    }
  },
})