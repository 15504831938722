import axios from './http'
import store from './store'
import Vue from 'vue'
import debounce from './debounce'

export default new Vue({
  data() {
    return {
      debounced: debounce(() => {
        if (store.state.cart.shippingAddress.postcode.length) {
          this.getMethods(store.state.cart.shippingAddress.postcode, 'GB').then(
            (options) => {
              store.commit('cart/setShippingMethods', options)
            },
            (e) => console.error(e),
          )
        }
      }, 300),
    }
  },
  methods: {
    setMethods() {
      this.debounced()
    },
    async getMethods(postcode, country) {
      var cartID = store.state.cart.cartID
      if (cartID === null) {
        alert('something went wrong')
      } else {
        return new Promise((resolve, reject) => {
          const response = axios.get(
            process.env.VUE_APP_VAVE_URL +
              '/api/shipping-method?postcode=' +
              postcode +
              '&country=' +
              country +
              '&cart_id=' +
              cartID,
            { withCredentials: true },
          )

          response.then(
            (r) => {
              resolve(r.data.data)
            },
            (e) => {
              reject(e)
            },
          )
        })
      }
    },
  },
})
