import tracker from "../tracker";

export default class viewProduct {

  constructor(product, cartId) {
    this.product = product;
    this.cartId = cartId;
  }

  dispatch() {
    tracker.publish(this)
  }
}