const debounce = (func, delay) => {
    let timeout
    return function e(...args) {

        const debounced = () => {
            clearTimeout(timeout)
            func(...args)
        }

        clearTimeout(timeout)
        timeout = setTimeout(debounced, delay)
    }
}

export default debounce