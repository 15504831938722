import { filter, forEach } from "lodash";

export default {
  
  filter(filters,products) {

    if (filters.subcategories.length == 0) {
        return products;
      }

      return filter(products, (product) => {
        var found = false;
        forEach(product.categories, (cat) => {
          const i = filters.subcategories.indexOf(cat.id);
          if (i > -1) {
            found = true;
          }
        });
        return found;
      });
  }
}