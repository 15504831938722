<template>
  <div>
    <div class="catlist">
      <header class="d-none d-md-block">
        <div class="container">
          <div class="row">
            <div class="col">
              <h1>Personal Protective Equipment</h1>
              <p>Welcome to Mstore, your go-to online shop for all your personal protective equipment (PPE) needs. With a commitment to safety and quality, we offer an extensive range of CE-certified PPE products designed to meet and exceed medical standards, ensuring the ultimate protection for trade professionals and weekend DIY enthusiasts alike.</p>
              <h2>Why Choose Mstore for Your PPE Needs?</h2>
              <ul>
                <li><strong>Wide Selection</strong>: From PPE gloves that offer dexterity and protection to durable safety glasses that shield your eyes, we have everything you need under one roof.</li>
                <li><strong>Quality Assurance</strong>: Our products not only meet European Conforming (CE) standards but are also designed with your safety and comfort in mind.</li>
                <li><strong>Innovative Solutions</strong>: We continuously expand our range with innovative PPE solutions, ensuring you have access to the latest and most effective protection available.</li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div class="container mt-4">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category hand">
              <div class="content">
                <h2>HAND PROTECTION</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Hand Protection',
                    react: true,
                  },
                }">
                  <button-primary>Shop Hand Protection</button-primary>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category respiratory">
              <div class="content">
                <h2>Respiratory PROTECTION</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Respiratory Protection',
                    react: true,
                  },
                }">
                  <button-primary>Shop Respiratory Protection</button-primary>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category eye">
              <div class="content">
                <h2>EYE PROTECTION</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Eye Protection',
                    react: true,
                  },
                }">
                  <button-primary>Shop Eye Protection</button-primary>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category hearing">
              <div class="content">
                <h2>HEARING PROTECTION</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Hearing Protection',
                    react: true,
                  },
                }">
                  <button-primary>Shop Hearing Protection</button-primary>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category clothing">
              <div class="content">
                <h2>PROTECTIVE CLOTHING</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Coveralls',
                    react: true,
                  },
                }">
                  <button-primary>Shop Protective Clothing</button-primary>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-4 mb-md-4 mb-lg-0">
            <div class="category head">
              <div class="content">
                <h2>HEAD PROTECTION</h2>
                <router-link :to="{
                  name: 'category',
                  params: {
                    category: 'Head Protection',
                    react: true,
                  },
                }">
                  <button-primary>Shop Head Protection</button-primary>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col">
            <h2>Trusted PPE for Every Task</h2>
            <p>
              At Mstore, we understand the importance of reliable PPE in ensuring safety and productivity, whether you're tackling a professional project or a home improvement task. That's why we're dedicated to providing you with trusted PPE solutions that meet your specific needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonPrimary from "../ui/buttons/buttonPrimary.vue";
export default {
  components: {
    buttonPrimary,
  },
};
</script>


<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "src/scss/breakpoints.scss";

.catlist {
  .category {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-bottom: 50%;
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin-bottom: 20px;
      text-transform: uppercase;
      word-spacing: 100vw;
      font-size: 3em;
      letter-spacing: -0.2rem;
    }
    text-align: center;
    background-color: #323232;
    color: white;
    padding-top: 15%;
    padding-bottom: 15%;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0px;
    }

    &.hand {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/HAND_PROTECTION_hbzhna");
    }

    &.respiratory {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/RESPIRATORY_PROTECTION_yiijzm");
    }

    &.eye {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/EYE_PROTECTION_nldieu");
    }

    &.hearing {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/HEARING_PROTECTION_gvkayj");
    }

    &.clothing {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/PROTECTIVE_CLOTHING_fso3uo");
    }

    &.head {
      background-image: url("https://res.cloudinary.com/mstore/image/upload/v1633939474/category%20banners/HEAD_PROTECTION_rokhyx");
    }
  }

  header {
    background-color: rgb(238, 238, 238);
    padding: 20px;
  }
}
</style>