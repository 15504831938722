/* eslint-disable no-unused-vars */
import Vue from 'vue'
import PortalVue from 'portal-vue'
import VueSocialSharing from 'vue-social-sharing'
import App from './App.vue'
import VueRouter from 'vue-router'
import 'es6-promise/auto'
import Vuex from 'vuex'
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import store from './store.js'
import './newsletter-popup-logic'
import './scss/app.scss'
import 'animate.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import heartbeatEvent from './events/eventTypes/heartbeat'
import routeProvder from './routes.mjs'
import productList from './components/catalogue/productList';

let components = {
  searchPage: () => import('./pages/search.vue'),
  account: () => import('./components/account/main.vue'),
  accountChangePassword: () => import('./components/account/change-password.vue'),
  accountPayments: () => import('./components/account/payments.vue'),
  accountAddresses: () => import('./components/account/addresses.vue'),
  accountAddressesAdd: () => import('./components/account/addresses-add.vue'),
  accountAddressesEdit: () => import('./components/account/addresses-edit.vue'),
  accountDetails: () => import('./components/account/details.vue'),
  accountOrders: () => import('./components/account/orders/list.vue'),
  accountOrdersShow: () => import('./components/account/orders/order.vue'),
  productInternal: () => import("./components/catalogue/productInternal"),
  checkout: () => import("./components/checkout/checkout"),
  resetCheckout: () => import("./components/checkout/resetCheckout.vue"),
  basket: () => import("./components/checkout/basket"),
  passthrough: () => import("./components/rv"),
  page: () => import("./components/layout/page"),
  realTimePreview: () => import("./pages/realTimePreview"),
  blog: () => import("./components/blog/home"),
  articleController: () => import("./components/blog/article/controller"),
  register: () => import("./components/authentication/register"),
  oneClickRegister: () => import("./components/authentication/oneClickRegister"),
  login: () => import("./components/authentication/login"),
  logout: () => import("./components/authentication/logout"),
  thankYou: () => import("./components/thankYou"),
  forgotPassword: () => import("./components/authentication/forgotPassword"),
  resetPassword: () => import("./components/authentication/resetPassword"),
  dynamicPage: () => import("./pages/dynamicPage"),
  p404: () => import("./pages/404"),
  productNotFound: () => import("./pages/productNotFound"),
  productList,
  special: {
    rileySports: () => import("./pages/special/rileySports"),
    sustainability: () => import("./pages/special/sustainability"),
    quotaFix: () => import("./pages/special/quotaFix"),
  },
  VATInvoiceRequested: () => import("./components/VATInvoiceRequested"),
}
const routes = routeProvder(components)

import icon from './components/ui/icon'
Vue.component('icon', icon)

Vue.use(Vuex)
Vue.use(VueBreadcrumbs);
Vue.use(PortalVue)
Vue.use(VueRouter)

Vue.use(PortalVue)
Vue.use(VueSocialSharing)

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue()


Vue.filter('money', (value) => {
  return (Math.round((value + Number.EPSILON) * 100) / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

window.setYoast = function (yoast) {
  return;
  // if (yoast == undefined) {
  //   return;
  // }
  // let innerHtml = document.head.innerHTML;

  // // Check if we have old Yoast data
  // const regex = /Yoast SEO Premium plugin./;
  // const found = innerHtml.match(regex);
  // if (found) {
  //   // If we found Yoast data, it means it is a user browsing the site. We don't care to 
  //   // replace the data as Prerender will only load the single pages with the right Yoast data.
  //   return;
  // }

  // // Add the new yoast meta tag
  // innerHtml += yoast;
  // document.head.innerHTML = innerHtml;
}

window.setTitle = function (title) {
  if (title == undefined) {
    title = '';
  }
  document.getElementsByTagName('title')[0].innerHTML = title + ' | MStore';
}

window.setMetaDescription = function (content = '') {
  document.querySelector('meta[name="description"]').setAttribute("content", content);
}

window.setMetaKeywords = function (content = '') {
  document.querySelector('meta[name="keywords"]').setAttribute("content", content);
}

window.setOgImage = function (content = '') {
  //find existing og:image tag
  let ogImage = document.querySelector('meta[property="og:image"]');
  if (ogImage) {
    ogImage.setAttribute("content", content);
  } else {
    //create new og:image tag
    ogImage = document.createElement('meta');
    ogImage.setAttribute("property", "og:image");
    ogImage.setAttribute("content", content);
    document.head.appendChild(ogImage);
  }
}

var isMobileApp = navigator.userAgent.match("vave-mobile-app") != null;

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// We have to wait the scroll events as we don't want the "top" event to be
// triggered when a new page is loaded (we scroll to the page top with js).
var monitorScrollCooldown = false;

// Auth middleware
router.beforeEach(async (to, from, next) => {
  // We are using localforage as storage, which is asyncrounous: we need to wait for the data to be ready
  await store.restored;

  monitorScrollCooldown = true
  setTimeout(() => {
    monitorScrollCooldown = false;
  }, 500);

  // Notify the mobile app that we are on the homepage,
  // so we can hide the back button
  if (isMobileApp) {
    // eslint-disable-next-line
    PageChanged.postMessage(
      JSON.stringify({
        page: to.params.slug
      })
    );
  }
  if (to.name && to.name == "account") {
    // If the customer tries to reach /account, redirect them to /account/details
    next({ name: 'account-details' });
  } else if (to.name && to.name.startsWith("account") && !store.state.auth.user.name) {
    next({ name: 'login' })
  } else if (to.name && to.name.startsWith("account") && store.state.auth.user.name && !store.state.auth.user.is_registered) {
    // Guest started checking out, then exited and browsed in the account section
    // Let's log them out
    next({ name: 'logout' })
  } else {
    next();
  }
})

import visitPageEvent from './events/eventTypes/visitPage'
// Tracking Events
router.beforeEach((to, from, next) => {
  if (!to.meta.avoidTrackingEvents) {
    new visitPageEvent(to.meta.pageTitle, to.params).dispatch();
  }
  next();
})

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
  window.__router__ = router
}

store.commit('app/canShow', false);
setTimeout(() => {
  store.commit('app/canShow', true);
}, 400);


import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

if (process.env.VUE_APP_DEBUG == "false") {
  Sentry.init({
    dsn: 'https://2e0941d04d0f4fb48fcfc8ab62f79af5@monitor.globusgroup.com/5',
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
  });
}

// Determines if we are visiting the website through the Mobile App
// where we override the user agent to be "vave-mobile-app"
Vue.mixin({
  data: function () {
    return {
      get isMobileAppMixin() {
        return isMobileApp;
      }
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("app/syncProducts")
      this.$store.dispatch("app/syncAspects")
      this.$store.dispatch("app/syncBlogArticles")
    }, 10)
  }
}).$mount('#app')

String.prototype.optimise = function () {
  return this.replace('upload/', 'upload/f_auto/q_auto/').replace('.png', '').replace('.jpeg', '').replace('.jpg', '');
}

// Send a message to ScrollEvent (ios mobile app), so they can hide/show the header
function notifyJS(message) {
  if (isMobileApp) {
    // eslint-disable-next-line
    ScrollEvent.postMessage(
      JSON.stringify({
        direction: message
      })
    );
  }
}

if (isMobileApp) {
  // Check the scroll of the page, if it's scrolling up, let's fire an event
  // so we can hide the header
  let lastScrollTop = 0;
  let firedEventUp = false;
  let firedEventDown = false;
  let firedEventTop = false;

  window.addEventListener("scroll", function () {
    if (monitorScrollCooldown == true) {
      return;
    }
    let currentYPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentYPosition > lastScrollTop) {
      if (!firedEventDown) {
        firedEventDown = true;
        firedEventUp = false;
        firedEventTop = false;
        notifyJS('down');
      }
    } else {
      if (!firedEventTop && currentYPosition <= 300) {
        firedEventDown = false;
        firedEventUp = false;
        firedEventTop = true;
        notifyJS('top');
        return;
      }
      if (!firedEventUp && !firedEventTop) {
        firedEventDown = false;
        firedEventUp = true;
        firedEventTop = false;
        notifyJS('up');
      }
    }
    lastScrollTop = currentYPosition <= 0 ? 0 : currentYPosition; // For Mobile or negative scrolling
  });
}

setInterval(() => {
  // Fire heartbeat event
  new heartbeatEvent().dispatch();
}, 30000);
