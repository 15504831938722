export default {
  computed: {
    subtotal() {
      var running = 0
      this.$store.state.cart.items.forEach((item) => {
        const unit_price = this.getPrice(item)
        running += unit_price * item.quantity
      })

      return running
    },
    bulkDiscountActive() {
      if (!this.$store.state.cart.serverTotals
        || !this.$store.state.cart.serverTotals.before_discounts
        || !this.$store.state.cart.serverTotals.after_bulk_discounts
      ) {
        return false;
      }
      // Check if we have any bulk pricing available for this cart
      let bulkDiscountExists =
        this.$store.state.cart.serverTotals.before_discounts.cents !=
        this.$store.state.cart.serverTotals.total.cents;
      // We check if the discount is coming from bulk pricing or a coupon
      return (
        bulkDiscountExists &&
        this.$store.state.cart.serverTotals.total.cents ==
        this.$store.state.cart.serverTotals.after_bulk_discounts.cents
      );
    },
  },
  methods: {
    getPrice(item) {
      var unit_price
      if (item.variation.id) {
        unit_price = item.variation.price
      } else {
        unit_price = item.product.price
      }
      return unit_price;
    },
    getBulkUnitPrice(item) {
      const full_unit_price = this.getPrice(item);
      return this.getBulkPrice(item, full_unit_price)
    },
    getBulkItemSubtotal(item) {
      const unit_price = this.getBulkUnitPrice(item)
      return (unit_price * item.quantity)
    },
    getItemSubtotal(item) {
      const unit_price = this.getPrice(item)
      return (unit_price * item.quantity)
    },
    itemAdded() {
      alert('Item added!')
    },
    getIsDiscounted(item) {
      if (!item.product.bulk) {
        return false;
      }
      // Get the total quantity of the products in the cart with same product
      // E.g. small and medium gloves are the same product, so are Evo Gray and Twilight
      var total_quantity = 0
      this.$store.state.cart.items.forEach((i) => {
        if (item.product.id == i.product.id) {
          total_quantity += parseInt(i.quantity)
        }
      });

      var discounted = false
      item.product.bulk.breaks.forEach((pb) => {
        if (
          total_quantity >= pb.quantity_from &&
          (total_quantity <= pb.quantity_to || pb.quantity_to === null)
        ) {
          discounted = pb.discount_percentage > 0;
        }
      })
      return discounted;
    },
    getBulkPrice(item, unit_price) {
      // Get the total quantity of the products in the cart with same product
      // E.g. small and medium gloves are the same product, so are Evo Gray and Twilight
      var total_quantity = 0
      this.$store.state.cart.items.forEach((i) => {
        if (item.product.id == i.product.id) {
          total_quantity += parseInt(i.quantity)
        }
      });
      if (item.product.bulk) {
        item.product.bulk.breaks.forEach((pb) => {
          if (
            total_quantity >= pb.quantity_from &&
            (total_quantity <= pb.quantity_to || pb.quantity_to === null)
          ) {
            // Take the bulk price and apply the discount taking care of floating point errors
            // convert back to cents to avoid floating point errors
            unit_price = unit_price * 100;
            unit_price = unit_price - (unit_price * pb.discount_factor)
            unit_price = Math.trunc(unit_price);
            unit_price = unit_price / 100;
          }
        })
      }
      return unit_price
    },
    increment(sku) {
      const promise = this.$store.dispatch('cart/increaseCartQty', sku)
      promise.then(
        () => { },
        (e) => {
          this.$store.commit('app/addNotification', {
            type: 'bad',
            title: 'Sorry',
            message: e.message,
            icon: 'circleExclamation',
          })
        },
      )
    },
    decrement(sku) {
      const promise = this.$store.dispatch('cart/decreaseCartQty', sku)
      promise.then(
        () => { },
        (e) => console.error(e),
      )
    },
    remove(sku) {
      const user_response = confirm('Really remove item from cart?')
      if (user_response) {
        const promise = this.$store.dispatch('cart/deleteFromCart', sku)
        promise.then(
          () => { },
          (e) => console.error(e),
        )
      }
    },
    image(item) {
      var image
      if (item.variation.id) {
        image = item.variation.image[0]
      } else {
        image = item.product.images[0]
      }

      if (image !== undefined) {
        var source = image.origin_src
        // Check if the link is already cloudinary
        if (source.indexOf('cloudinary.com') > -1) {
          return source.replace('upload', 'upload/w_50,h_50')
        }
        return (
          'https://res.cloudinary.com/mstore/image/fetch/w_50,h_50/' + source
        )
      }

      return ''
    },
  },
}
