// sends tracking events to GA
// SUPPORTED EVENTS
// ------------------
// viewProduct
// selectVariation
// viewProductList
// addToCart
// register
// checkout
// beginCheckout
// stepCheckout

import Vue from 'vue'

import addToCart from './eventTypes/addToCart'
import checkout from './eventTypes/checkout'
import beginCheckout from './eventTypes/beginCheckout'
import hit from './eventTypes/hit'


export default new Vue({
  methods: {
    init(tracker) {
      tracker.subscribe(this.event)
    },
    event(event) {
      if (event instanceof addToCart) {
        this.addToCart(event)
      }
      if (event instanceof beginCheckout) {
        this.beginCheckout(event)
      }
      if (event instanceof checkout) {
        this.checkout(event)
      }
      if (event instanceof hit) {
        // this.hit(event.page)
      }
    },
    addToCart(event) {
      var item = {
        content_name: event.product.name,
        // content_category: 'Apparel & Accessories > Shoes',
        content_ids: [event.product.sku],
        content_type: 'product',
        value: event.product.price,
        currency: 'GBP',
      }
      if (event.variation.id) {
        item.value = event.variation.price
        item.content_ids = [event.variation.sku]
        item.content_name = event.product.name + ' ' + event.variation.name
      }
      window.fbq('track', 'AddToCart', item)
    },
    beginCheckout(event) {
      var itemsIds = []
      event.products.forEach(product => {
        itemsIds.push(product.id)
      })
      var contents = [];
      event.products.forEach(product => {
        var item = {
          id: product.id,
          quantity: product.quantity,

        }
        contents.push(item)
      })
      window.fbq('track', 'InitiateCheckout', {
        content_ids: itemsIds,
        currency: 'GBP',
        num_items: event.products.length,
        value: event.total,
        contents
      })
    },
    checkout(event) {
      var itemsIds = []
      event.products.forEach(product => {
        itemsIds.push(product.id)
      })
      var contents = [];
      event.products.forEach(product => {
        var item = {
          id: product.id,
          quantity: product.quantity,

        }
        contents.push(item)
      })
      window.fbq('track', 'Purchase', {
        content_ids: itemsIds,
        currency: 'GBP',
        num_items: event.products.length,
        value: event.total,
        contents
      })
    }
  },
})