import tracker from "../tracker";

export default class openBlogArticle {

  constructor(articleTitle) {
    this.articleTitle = articleTitle;
  }

  dispatch() {
    tracker.publish(this)
  }
}