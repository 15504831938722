import store from "./store.js";

// After 30 seconds trigger the popup check
setTimeout(() => {
    let path = window.location.pathname
    let excludedRoutes = [
        "/checkout",
        "/thank-you",
        "/basket",
        "/login",
        "/register",
        "/forgot-password",
        "/reset-password",
    ];
    if (excludedRoutes.filter(route => {
        if (path.includes(route)) {
            return true;
        }
    }).length > 0) {
        // If the user is checking out or doing active stuff, let's not show the popup
        return;
    }

    // We want to show the popup only if the user is not logged in, if  he's never opted in for marketing,
    // or if he has never dismissed the popup or it was dismissed more than 14 days ago
    if (store.state.app.registeredToNewsletter == true) {
        return;
    }
    var lastDismissedOn = store.state.app.newsletterPopupDismissedAt
    if (lastDismissedOn == null || getNumberOfDays(new Date(lastDismissedOn), new Date) >= 14) {
        if (store.state.auth.user && store.state.auth.user.marketing_opt_in === true) {
            // user has already subscribed to the newsletter
            return;
        }
        // NOTE: dosn't show for some reason but breaks the page, need investigation
        store.commit("app/showNewsletterPopup", true);
    }
}, 30000);

function getNumberOfDays(start, end) {
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = end.getTime() - start.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
}