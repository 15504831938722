var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui',{attrs:{"label":_vm.label,"active":_vm.active}},[_c('p',[_vm._v("Please specify price range")]),_c('div',{staticClass:"slider-outer"},[_c('vue-slider',{attrs:{"marks":{
          0: '£0',
          5: '£5',
          10: '£10',
          15: '£15',
          20: '£20',
          30: '£30',
          40: '£40',
          50: '£50+',
        },"min":0,"max":50,"interval":1,"enableCross":false,"tooltipFormatter":'£{value}',"dot-attrs":{ 'aria-label': 'Select Price Range' }},model:{value:(_vm.prices),callback:function ($$v) {_vm.prices=$$v},expression:"prices"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }