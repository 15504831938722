import tracker from "../tracker";

export default class marketingOptIn {

    constructor(email, phone, firstName, lastName) {
        this.email = email;
        this.phone = phone;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    dispatch() {
        tracker.publish(this)
    }
}