import debouncer from "../../store/debouncers/cart";


export default store => {

    store.subscribe((mutation) => {
        if (mutation.type == "cart/addItemUndebounced") {
            debouncer._sendItemToServer(mutation, store)
        }
        if (mutation.type == "cart/addItem") {
            debouncer._addItem(mutation, store)
        }
        if (mutation.type == "cart/deleteItem") {
            debouncer._deleteItem(mutation,store)
        }
        if (mutation.type == "cart/setQty") {
            debouncer._setQty(mutation,store)
        }
    })
}